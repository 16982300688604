import { Injectable } from '@angular/core';
import { map, ReplaySubject } from 'rxjs';
import { IFormDropdownOption } from '../../../form-controls/form-controls.const';
import { MasterDataService } from '../../master-data/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class PricingUnitService {
  public values: IFormDropdownOption[] = [];
  public lookupValues: string[] = [];

  public values$ = new ReplaySubject<IFormDropdownOption[]>(1);

  constructor(private masterDataService: MasterDataService) {
    this.getPricingUnits().subscribe();
  }

  public getPricingUnits() {
    return this.masterDataService
        .getMasterData('pricing-units')
        .pipe(map( data => {
          this.values = data;
          this.values$.next(data);
          for (let item of this.values) {
            this.lookupValues[item.value] = item.label;
          }
          this.values = data;
          return data;
        }));
  }
}
