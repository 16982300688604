<div class="sidesheet-breadcrumb-container">
  <div *ngIf="formerSidesheets?.length > 1 && sideSheetConfig?.breadcrumbBackButtonVisible" style="margin-right: 0.2rem;align-items: center;display: flex;">
    <button class="zoom-in" mat-icon-button (click)="onBackButton()">
      <img src="/assets/svg/sidesheet-back-button.svg" style="width: 2rem" alt="">
    </button>
  </div>
  
    <div class="sidesheet-breadcrumb-menu-container">

        <ol class="sidesheet-breadcrumb">
            <li *ngFor="let item of formerSidesheets; let isLast = last; let i = index"
                [class.active]="isLast" class="sidesheet-breadcrumb-item" >

              <ng-container *ngIf="isLast">
                <span>{{item?.content?.title | translate}}</span>
                <span class="sidesheet-title" *ngIf="sideSheetContent?.subTitle">
                  {{"   (" + (sideSheetContent?.subTitle | translate) + ")"}}
                </span>
              </ng-container>

              <ng-container *ngIf="!isLast">
                <a *ngIf="!isLast" href="javascript:void(0)"
                   (click)="loadComponent(item?.componentRef?.componentType, item?.content, item?.config)">{{item?.content?.title | translate}}</a>
              </ng-container>
            </li>
        </ol>
    </div>
    <div class="sidesheet-breadcrumb-title-action-container">
        @if (breadcrumbActionsTemplate) {
            <ng-container *ngTemplateOutlet="breadcrumbActionsTemplate"></ng-container>
        } @else {
            <ng-container *ngTemplateOutlet="sidesheetBreadCrumbDefaultActionsTemplate"></ng-container>
        }
    </div>
</div>


<ng-template #sidesheetBreadCrumbDefaultActionsTemplate>
    <button mat-icon-button class="sidesheet-expand-button zoom-in" (click)="toggleFullScreen()"
        *ngIf="sideSheetConfig?.breadcrumbFullscreenVisible">
      <img *ngIf="!sideSheetConfig?.fullscreen" src="/assets/svg/expand.svg" style="padding-top: 0.2rem">
      <mat-icon *ngIf="sideSheetConfig?.fullscreen">fullscreen_exit</mat-icon>
    </button>
    <button mat-icon-button (click)="onCloseButton()" class="zoom-in sidesheet-close-breadcrumb"
        [disabled]="closeButton.disabled" *ngIf="sideSheetConfig?.breadcrumbCloseVisible">
      <img  src="/assets/svg/sidesheetclose.svg">
    </button>
</ng-template>
