import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
  OnDestroy,
  ViewChild, Optional, Inject,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { IFormDropdownOption } from '../../form-controls';
import {
  fadeAnimation,
  ITableOption,
  ITableView,
  TableViewComponent,
} from '../../general-components';
import {
  AnswerModel,
  AnswerTypeConfigurationModel,
  PaginationDataModel,
  QuestionSetAnswerListModel
} from '../../models';
import { CollaborationService } from '../../services/collaboration-service/collaboration.service';
import { MismatchAnswersService } from '../../services/mismatch-answers/mismatch-answers.service';
import { FormatCleanerService } from '../../services/format-cleaner/format-cleaner.service';
import { PermissionService } from '../../services/permissions/permission.service';
import { AddQuestionSetComponent } from './add-question-set/add-question-set.component';
import { QuestionSetRenderService } from './question-set-render.service';
import { MismatchAnswersComponent } from './mismatch-answers/mismatch-answers.component';
import { QuestionSetCompareComponent } from './question-set-compare/question-set-compare.component';
import { FormatCompletenessService } from '../../services';
import { takeUntil } from 'rxjs/operators';
import { UpdateTimeByUtils } from '../../utilities';
import { SIDE_SHEET_CONTENT, SideSheetConfig, SideSheetContent, SideSheetService } from 'tuula-common';


@Component({
  selector: 'app-question-set-render',
  templateUrl: './question-set-render.component.html',
  styleUrls: ['./question-set-render.component.scss'],
  animations: [fadeAnimation],
})
export class QuestionSetRenderComponent
  implements OnInit, OnChanges, OnDestroy  {
  private readonly destroyed$ = new Subject<boolean>();

  @Input() entityId: string | number;
  @Input() entity: string;
  @Input() entityVersion: number;
  @Input() isEditable? = true;
  @Input() isEscalated: boolean = false;
  @Input() isCoresponsible? = false;
  @Input() isClientPortal? = false;
  @Input() services: string[];
  @Input() inheritingEntityId?: string = null;
  @Input() inheritingEntityType?: string = null;
  @Input() inheritingEntityVersion?: number = null;
  @Input() formatUnanswered$ = new Subject<void>();
  @Input() applicationViewMode: boolean = false;
  @Input() isSideSheet: boolean = false;
  @Input() refreshPage: boolean = false;
  @Output() changeOccurred = new EventEmitter();
  @Output() updateTime = new EventEmitter<any>();
  @Output() sideSheetDeleteFormat = new EventEmitter<any>();

  @ViewChild(TableViewComponent) tableViewComponent: TableViewComponent;

  isFormatVisible: boolean = true;

  mismatchOnScreen: boolean = false;

  questionSetAnswerListItem: QuestionSetAnswerListModel;
  questionSetAnswerId: string | number;
  currentQuestionSet: QuestionSetAnswerListModel =
    new QuestionSetAnswerListModel();
  answers: AnswerModel[] = [];

  questionSet$ = new BehaviorSubject<{
    data: QuestionSetAnswerListModel[];
    count: number;
  }>(null);
  answersFormArray = this.formBuilder.array([]);
  optionsForChoice: [options: AnswerTypeConfigurationModel[]];

  answeredAllQuestionsColumn = {
    id: 'answeredQuestions',
    name: 'questionset.labels.Status',
    type: 'progress',
    progress: 'totalQuestions',
    format: '',
    formatCompleteness: 1,
  };

  answeredMandatoryQuestionsColumn = {
    id: 'answeredMandatoryQuestions',
    name: 'questionset.labels.Status',
    type: 'progress',
    progress: 'mandatoryQuestions',
    format: '',
    formatCompleteness: 2,
  };

  filterForm = new FormGroup({
    answeredFilter: new FormControl(1),
    mandatoryFilter: new FormControl(false),
  });

  dropdownOptions: IFormDropdownOption[] = [
    { label: 'general.labels.All', value: 1 },
    { label: 'questionset.labels.Unanswered', value: 2 },
  ];

  public tableOptions: ITableOption = null!;

  // default table view
  public tableView: ITableView = {
    pageIndex: 0,
    pageSize: 10,
    sortColumn: 'name',
    sortDirection: 'asc',
    search: '',
  };

  public menuActions = [
    {
      icon: 'refresh',
      tooltip: 'questionset.actions.UpdateFormat',
      method: (row) => this.updateFormat(row),
      conditionMethod: (row) => {
        return (
          row.updateAvailable &&
          this.permissionService.getPermission(this.permissionString(), 'or') &&
          !this.applicationViewMode
        );
      },
    },
    {
      icon: 'delete',
      tooltip: 'general.actions.Delete',

      method: (row) => this.removeSet(row),

      conditionMethod: () => this.isEditable && !this.applicationViewMode,
    },
  ];

  tabs: any[] = [];
  selectedIndex = 0;
  isFirstChange = true;

  constructor(
    private questionSetRenderService: QuestionSetRenderService,
    public formBuilder: FormBuilder,
    public dialog: MatDialog,
    public permissionService: PermissionService,
    public formatCleanerService: FormatCleanerService,
    private collaborationService: CollaborationService,
    private mismatchAnswersService: MismatchAnswersService,
    private formatCompletenessService: FormatCompletenessService,
    private sideSheetService: SideSheetService,
    @Optional()
    @Inject(SIDE_SHEET_CONTENT)
    private sideSheetContent: SideSheetContent,
  ) {
    this.tableOptions = this.buildTable();
  }

  onToggle(): void {
    this.isFormatVisible = !this.isFormatVisible;
  }

  ngOnInit(): void {
    this.checkFormatCompleteness();
    if (!this.isClientPortal) this.tableOptions.menuActions = this.menuActions;
    this.getQuestionSetAnswers(true);

    this.tableOptions.showAdd =
      this.isEditable &&
      this.permissionService.getPermission('formatAnswers:create') &&
      !this.applicationViewMode &&
      (this.entity === 'careprogram'
        ? this.permissionService.getPermission('careprogram:zpm:carefile:full')
        : true);
  }

  private checkFormatCompleteness() {
    if (!this.isClientPortal) {
      this.formatCompletenessService
        .getFormatCompletenessForTenant()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((res) => {
          if (res) {
            this.tableViewComponent.addColumn(
              res === 1
                ? this.answeredAllQuestionsColumn
                : this.answeredMandatoryQuestionsColumn,
            );
          }
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changesOccurred = {
      isCoresponsible: false,
      entityVersion: false,
      entityId: false,
      inheritingEntityId: false,
      inheritingEntityVersion: false,
      isEscalated: false,
    };
    if (
      changes.isCoresponsible &&
      changes.isCoresponsible.previousValue !==
      changes.isCoresponsible.currentValue
    ) {
      this.isCoresponsible = changes.isCoresponsible.currentValue;
      changesOccurred.isCoresponsible = true;
    }

    if (
      changes.entityVersion &&
      changes.entityVersion.previousValue !== changes.entityVersion.currentValue
    ) {
      this.entityVersion = changes.entityVersion.currentValue;
      changesOccurred.entityVersion = true;
      this.tabs = [];
      this.getQuestionSetAnswers();
    }

    if (
      changes.entityId &&
      changes.entityId.previousValue !== changes.entityId.currentValue
    ) {
      this.entityId = changes.entityId.currentValue;
      changesOccurred.entityId = true;
      this.tabs = [];
      this.getQuestionSetAnswers();
    }

    if (
      changes.inheritingEntityId &&
      changes.inheritingEntityId.previousValue !==
      changes.inheritingEntityId.currentValue
    ) {
      this.inheritingEntityId = changes.inheritingEntityId.currentValue;
      changesOccurred.inheritingEntityId = true;
    }

    if (
      changes.inheritingEntityVersion &&
      changes.inheritingEntityVersion.previousValue !==
      changes.inheritingEntityVersion.currentValue
    ) {
      this.inheritingEntityVersion =
        changes.inheritingEntityVersion.currentValue;
      changesOccurred.inheritingEntityVersion = true;
    }

    if (
      changes.isEscalated &&
      changes.isEscalated.previousValue !== changes.isEscalated.currentValue
    ) {
      this.isEscalated = changes.isEscalated.currentValue;
      changesOccurred.isEscalated = true;
    }
    if (
      !this.mismatchOnScreen &&
      (changesOccurred.isEscalated ||
        changesOccurred.entityId ||
        changesOccurred.entityVersion ||
        (changesOccurred.inheritingEntityId &&
          changesOccurred.inheritingEntityVersion))
    ) {
      if (!this.isFirstChange) {
        this.getQuestionSetAnswers(true, true);
        this.isFirstChange = false;
      }
    }
    if (changes.isEditable) {
      this.isEditable = changes.isEditable.currentValue;
    }

    this.tableOptions.showAdd =
      this.isEditable &&
      this.permissionService.getPermission('formatAnswers:create') &&
      !this.applicationViewMode &&
      (this.entity === 'careprogram'
        ? this.permissionService.getPermission('careprogram:zpm:carefile:full')
        : true);
    if (changes.refreshPage){
       this.getQuestionSetAnswers(false, true);
        this.answersFormArray.clear();
    }
  }

  public getQuestionSetAnswers(
    initialized?: boolean,
    updateOpenTabs?: boolean,
  ) {
    this.changeOccurred.emit();
    this.refreshSetTable(this.tableView, initialized, updateOpenTabs);
  }

  public removeSet(row: any): void {

    // Report formats can only be deleted if the user has readWrite permissions
    if (this.entity === 'report') {
      if (
        row.roles.readWrite.findIndex(
          (role) => role === this.permissionService.currentRole,
        ) > -1
      ) {
        if (this.isSideSheet) {
          this.sideSheetDeleteFormat.emit({
            data: row,
            entity: this.entity,
            entityId: this.entityId.toString(),
            entityVersion: this.entityVersion,
            isSideSheet: this.isSideSheet ? 'true' : '',
          });
        } else {
          this.deleteFormat(row);
        }
      }
    } else if (this.isEditable) {
      this.deleteFormat(row);
    }
  }


  updateFormat(format: any) {
    const dialogRef = this.dialog.open(QuestionSetCompareComponent, {
      data: {
        id: format.id,
        entity: this.entity,
        entityId: this.entityId,
        entityVersion: this.entityVersion,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.questionSetRenderService
          .applyAvailableUpdateForQuestionSetAnswer(
            this.entity,
            this.entityId,
            this.entityVersion,
            format.id,
            result,
          )
          .subscribe(() => {
            this.getQuestionSetAnswers(false, true);
          });
      }
    });
  }

  private checkMismatch(
    entity: string,
    entityId: string | number,
    entityVersion: number,
  ) {
    this.mismatchAnswersService
      .getMismatchAnswers(entity, entityId, entityVersion)
      .subscribe((mismatch) => {
        if (mismatch) {
          const dialogRef = this.dialog.open(MismatchAnswersComponent, {
            data: mismatch,
          });

          dialogRef.afterClosed().subscribe((result) => {
            this.mismatchOnScreen = false;
            if (result) {
              this.mismatchAnswersService
                .updateMismatchAnswer(result)
                .subscribe(() => {
                  this.getQuestionSetAnswers(false, true);
                });
            }
          });
        } else {
          this.getQuestionSetAnswers(false, true);
        }
      });
  }

  public addQuestionSet(): void {
    if (this.isEditable) {
      const dialogRef = this.dialog.open(AddQuestionSetComponent, {
        data: {
          entity: this.entity,
          entityId: this.entityId,
          entityVersion: this.entityVersion,
          isCoresponsible: this.isCoresponsible,
          services: this.services,
          inheritingEntityId: this.inheritingEntityId,
          inheritingEntityType: this.inheritingEntityType,
          inheritingEntityVersion: this.inheritingEntityVersion,
        },
      });

      dialogRef.afterClosed().subscribe((dialogData) => {
        if (dialogData) {
          this.questionSetRenderService
            .addQuestionSets(
              this.entity,
              this.entityId,
              this.entityVersion,
              dialogData,
              this.inheritingEntityType,
              this.inheritingEntityId,
              this.inheritingEntityVersion,
            )
            .subscribe(() => {
              this.getQuestionSetAnswers(true, true);
              this.questionSetRenderService.assignedQuestionSets = [];
              this.questionSetRenderService.availableQuestionSets = [];
            });
        }
      });
    }
  }

  public addQuestionSetInSideSheet(): void {
    const data = {
      entity: this.entity,
      entityId: this.entityId,
      entityVersion: this.entityVersion,
      isCoresponsible: this.isCoresponsible,
      services: this.services,
      inheritingEntityId: this.inheritingEntityId,
      inheritingEntityType: this.inheritingEntityType,
      inheritingEntityVersion: this.inheritingEntityVersion,
    }
    this.loadSideSheet('questionset.titles.AddFormat', [this.sideSheetService.createOkButton()], data);
  }

  public refreshSetTable(
    data?: ITableView,
    initialized?: boolean,
    updateOpenTabs?: boolean,
  ): void {
    this.tableView = data;
    this.questionSetRenderService
      .getQuestionSets(
        this.entity,
        this.entityId,
        this.entityVersion,
        PaginationDataModel.fromTableView(this.tableView),
        {
          coresponsible: this.isCoresponsible,
        },
      )
      .subscribe((data) => {
        if (
          data &&
          this.entity !== 'application' &&
          this.entity !== 'report' &&
          this.entity !== 'careprogram'
        ) {
          const hasMismatch =
            data.docs.filter(
              (questionSet) => questionSet.mismatchExists === true,
            ).length > 0;
          if (hasMismatch && !this.mismatchOnScreen) {
            this.mismatchOnScreen = true;
            this.checkMismatch(this.entity, this.entityId, this.entityVersion);
          } else {
            this.fireNextQuestionSet(data, initialized, updateOpenTabs);
          }
        } else {
          this.fireNextQuestionSet(data, initialized, updateOpenTabs);
        }
      });
  }

  private fireNextQuestionSet(
    data: { docs: QuestionSetAnswerListModel[]; totalDocs: number },
    initialized?: boolean,
    updateOpenTabs?: boolean,
  ) {
    const updateAt = data.docs.map((item) => ({
      updatedAt: new Date(item?.updatedAt) ?? null,
      updatedBy: item?.updatedBy?.fullName ?? null,
    }));
    this.updateTime.emit(UpdateTimeByUtils.getLatestObj(updateAt));

    this.questionSet$.next({ data: data.docs, count: data.totalDocs });
    if (this.tabs.length > 0 && updateOpenTabs) this.updateTabs(data.docs);
    if (initialized && data.docs.length === 1) {
      this.onRowClick(data.docs[0]);
    }
  }

  public onRowClick(data: any): void {
    const index = this.tabs.findIndex((x) => x.name === data.title);

    if (index >= 0) this.selectedIndex = index;
    else {
      this.tabs.push({ name: data.title, data: data });
      this.selectedIndex = this.tabs.length - 1;
      this.collaborationService.sendMessage(
        'userEnter',
        'format' +
        this.tabs[this.selectedIndex].data.id +
        'entity' +
        this.entityId +
        this.entity +
        this.entityVersion,
      );
    }
  }

  updateTabs(docs: QuestionSetAnswerListModel[]): void {
    if (this.tabs.length > 0) {
      const tabsForDeletion = [];
      for (let [i, tab] of this.tabs.entries()) {
        const tabData = docs.find((doc) => doc.title === tab.name);
        if (tabData) {
          this.tabs[i] = { name: tabData.title, data: tabData };
        } else {
          tabsForDeletion.push(tab);
        }
      }
      if (tabsForDeletion.length > 0) {
        for (let deletionTab of tabsForDeletion) {
          const tabIndex = this.tabs.findIndex(
            (tab) => tab.name === deletionTab.name,
          );
          if (tabIndex > -1) this.removeTab(tabIndex);
        }
      }
    }
  }

  closeTab(event: any, index: number) {
    event.stopPropagation();
    this.removeTab(index);
  }

  private removeTab(index: number) {
    if (index > -1) {
      this.collaborationService.sendMessage(
        'userLeave',
        'format' +
        this.tabs[index].data.id +
        'entity' +
        this.entityId +
        this.entity +
        this.entityVersion,
      );
      this.tabs.splice(index, 1);
      if (this.tabs.length > 0 && index > this.tabs.length - 1)
        this.selectedIndex = this.tabs.length - 1;
    }
  }

  deleteFormat(row: any, isSideSheet?: boolean) {
    this.questionSetRenderService
      .deleteQuestionSet(
        row.id,
        this.entity,
        this.entityId.toString(),
        this.entityVersion,
        isSideSheet ? 'true' : '',
      )
      .subscribe(() => {
        this.getQuestionSetAnswers(false, true);
        this.answersFormArray.clear();
      });
  }

  loadSideSheet(title, buttons, data, type?: string) {
    const content: SideSheetContent = new SideSheetContent();
    content.title = title;
    content.buttons = buttons;
    content.data = data;

    if (this.sideSheetContent.subTitle) {
      content.subTitle = this.sideSheetContent.subTitle;
    }

    const config: SideSheetConfig = new SideSheetConfig();
    if (type === 'view') {
      config.commandLineVisible = true;
    }

    const sideSheetRef = this.sideSheetService.loadComponent(
      AddQuestionSetComponent,
      content,
      config,
      false,
    );

    sideSheetRef.afterClosed().subscribe((result) => {
      if (result) {
        this.questionSetRenderService
          .addQuestionSets(
            this.entity,
            this.entityId,
            this.entityVersion,
            result,
            this.inheritingEntityType,
            this.inheritingEntityId,
            this.inheritingEntityVersion,
          )
          .subscribe(() => {
            this.getQuestionSetAnswers(true, true);
            this.questionSetRenderService.assignedQuestionSets = [];
            this.questionSetRenderService.availableQuestionSets = [];
          });
      }
    });
  }

  ngOnDestroy() {
    for (let tab of this.tabs) {
      this.collaborationService.sendMessage(
        'userLeave',
        'format' +
        tab.data.id +
        'entity' +
        this.entityId +
        this.entity +
        this.entityVersion,
      );
    }
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private permissionString(): string | null {
    switch (this.entity) {
      case 'application':
        return 'application:full|application:create';
      case 'intake':
        return 'intake:manage|intake:create';
      case 'careplan':
        return 'careplan:manage|careplan:create';
      case 'report':
        return 'report:manage|report:create';
      case 'evaluation':
        return 'careplan:evaluation:manage|evaluation:create';
      case 'careprogram':
        return 'careprogram:zpm:carefile:full';
    }
    return 'null';
  }

  buildTable(): ITableOption {
    return {
      name: 'questionset.titles.Formats',
      dataObservable: this.questionSet$,
      columns: [
        {
          id: 'title',
          name: 'questionset.labels.Name',
          type: 'string',
          format: '',
          width: 80,
        },
      ],
      inlineActions: null,
      menuActions: [],
      showHeader: false,
      showPagination: true,
      showSearch: false,
      externPagination: true,
      defaultPageSize: 10,
      defaultSortColumn: 'name',
      defaultSortDirection: 'asc',
    };
  }
}
