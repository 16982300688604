<app-panel [formGroup]="mainTherapistForm" type="popup">
  <app-panel-title
    title="careprogram.labels.MainTherapist"
    titleIcon="new_label"
  >
  </app-panel-title>
  <app-panel-form-row>
    <app-form-input-dropdown
      placeholder="careprogram.labels.MainTherapist"
      formControlName="employee"
      [dropdownService]="carefileEmployeeService"
      [options]="$any(carefileEmployeeService.values)"
      [search]="true"
      [filterObject]="mainTherapistFilterObject"
      [objectAsValue]="true"
      labelProperty="fullName"
      [selectSingle]="true"
    ></app-form-input-dropdown>
    <app-label
      [labelValue]="{
        label: 'employee.labels.EmployeeNumber',
        value: mainTherapistForm.get('employee').value?.employeeNumber
      }"
    ></app-label>
  </app-panel-form-row>
  <app-panel-form-row>
    <app-form-input-date
      formControlName="startDate"
      placeholder="general.labels.StartDate"
      [dateFilter]="dateFilter"
      [minDate]="$any(minStartDate)"
      [maxDate]="$any(maxStartDate)"
      (openedDateStream)="onOpened('start')"
      (closedDateStream)="onClosed()"
      (changeDate)="onchangeDate('startDate')"
    ></app-form-input-date>
    <app-form-input-date
      formControlName="endDate"
      placeholder="general.labels.EndDate"
      [dateFilter]="dateFilter"
      [minDate]="$any(minEndDate)"
      [maxDate]="$any(maxEndDate)"
      (openedDateStream)="onOpened('end')"
      (closedDateStream)="onClosed()"
      [dbclickNull]="true"
      (changeDate)="onchangeDate('endDate')"
    ></app-form-input-date>
  </app-panel-form-row>
  <app-panel-form-row *ngIf="!!errorMessage">
    <p class="warning">{{ '*' + errorMessage }}</p>
  </app-panel-form-row>
</app-panel>
<div style="display: flex">
  <span class="c4p-button-cancel" (click)="onDismiss()" translate
    >general.labels.Cancel</span
  >
  <span class="c4p-button-ok" *ngIf="!viewOnly" (click)="onConfirm()">
    <span>OK</span>
  </span>
</div>
