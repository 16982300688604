import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  ConfirmAction,
  SideSheetConfig,
  SideSheetContent,
  SideSheetTooltip,
  SideSheetTooltipButtonEvent,
} from '../../../models/side-sheet';
import { SideSheetService } from '../../../services';
import { Subscription } from 'rxjs';
import { NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-sidesheet-tooltip',
  templateUrl: './sidesheet-tooltip.component.html',
  styleUrls: ['./sidesheet-tooltip.component.scss'],
  standalone:true,
  imports: [NgIf,NgStyle,TranslateModule,NgTemplateOutlet]
})
export class SidesheetTooltipComponent implements OnInit, OnDestroy {
  @Input() sideSheetConfig: SideSheetConfig;
  @Input() sideSheetContent: SideSheetContent;

  @Output() tooltipButtonClicked = new EventEmitter<SideSheetTooltipButtonEvent>();

  tooltipData: SideSheetTooltip;

  private subscription: Subscription = new Subscription();

  constructor(private sideSheetService: SideSheetService) {}

  ngOnInit(): void {
    this.subscription.add(this.sideSheetService.sideSheetHost$.subscribe(data => {
      this.closeTooltip();
    }));

    this.subscription.add(this.sideSheetService.tooltipSubject$.subscribe(tooltip => {
      this.tooltipData = tooltip;
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  closeTooltip() {
    this.tooltipData = null;
  }

  onExecuteButtonAction(confirmed: boolean) {
    this.tooltipButtonClicked.emit({ button: this.tooltipData?.confirmAction?.button, confirmed, config: this.sideSheetConfig, content: this.sideSheetContent });
    this.closeTooltip();
  }

  onClose() {
    this.closeTooltip();
  }
}
