import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import { IFormDropdownOption } from '../../../form-controls';
import { MasterDataService } from '../../master-data/master-data.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CompanyTypeService {
  public lookupValues: string[] = [];

  public get values() {
    return this.values$.value;
  }

  public values$ = new BehaviorSubject<IFormDropdownOption[]>(null);

  constructor(private masterDataService: MasterDataService) {
    this.getCompanyTypes();
  }

  public getCompanyTypes() {
    this.masterDataService.getMasterData('company-types').subscribe((data) => {
      this.values$.next(data);
      for (let item of this.values) {
        this.lookupValues[item.value] = item.label;
      }
    });
  }

  public fetchCompanyTypes(): Observable<any[]> {
    return this.masterDataService.getMasterData('company-types').pipe(map((data:any) => {
        return data.map((item) => {
          return {label: item.label, value: item.value, id:item.value }
        })
      })
    );
  }
}
