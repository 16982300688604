<!-- https://github.com/Agranom/ngx-material-timepicker/issues/126 -->

<div class="c4p-form-input" (dblclick)="setNow()">
  <mat-form-field>
    <mat-label class="c4p-form-label">{{ placeholder | translate }}</mat-label>
    <input
      matInput
      [ngxTimepicker]="toggleTimepicker"
      [format]="24"
      [formControl]="valueControl"
      [required]="required"
      (blur)="onTimeTouchedCallback(timepickerInput)"
      [disableClick]="true"
      (keydown)="onKeydown($event)"
      maxlength="5"
      #timepickerInput
      [errorStateMatcher]="errorStateMatcher"
    />
    <mat-error *ngFor="let error of ngControl.errors | keyvalue">
      @if(errorLabels && errorLabels[error.key]){
        <span>
          {{ errorLabels[error.key] | translate }}
        </span>
      }@else {
        <ng-container #defaultError *ngIf="DEFAULT_ERRORS.has(error.key)">
          <span>{{ DEFAULT_ERRORS.get(error.key) | translate }}&nbsp;</span>
          <span *ngIf="error.key === 'matDatetimePickerMin'">{{
            error.value?.min | appTime
          }}</span>
          <span *ngIf="error.key === 'matDatetimePickerMax'">{{
            error.value?.max | appTime
          }}</span>
        </ng-container>
      }
      
    </mat-error>
  </mat-form-field>
  <ngx-material-timepicker-toggle
    [for]="toggleTimepicker"
  ></ngx-material-timepicker-toggle>
</div>
<ngx-material-timepicker
  #toggleTimepicker
  [minutesGap]="stepMinute"
  [value]="adjustedTimeValue"
></ngx-material-timepicker>
