<div
  class="c4p-form-input-small{{ width }}"
  [ngClass]="{ 'readonly-wrapper': readonly }"
>
  <div style="flex-direction: column">
    <mat-checkbox
      [formControl]="valueControl"
      [required]="required"
      (click)="onTouchedCallback()"
      [labelPosition]="labelPosition"
      >{{ placeholder | translate
      }}<span *ngIf="required">*</span></mat-checkbox
    >
    <span style="font-size: 85%" *ngIf="valueControl.touched">
      <mat-error *ngFor="let error of ngControl.errors | keyvalue">
        @if(errorLabels && errorLabels[error.key]){
          <span>
            {{ errorLabels[error.key] | translate }}
          </span>
        }@else {
          <ng-container
            #defaultError
            *ngIf="DEFAULT_ERRORS.has(error.key)"
            [ngSwitch]="error.key"
          >
            <span>{{ DEFAULT_ERRORS.get(error.key) | translate }}</span>
          </ng-container>
        }

      </mat-error>
    </span>
  </div>
</div>
