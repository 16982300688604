<ng-container
  *ngIf="formGroup"
  [formGroup]="formGroup"
  [ngSwitch]="dynamicFormInput?.controlType"
>
  <ng-container *ngSwitchCase="'text'">
    <app-form-input-text
      *ngSwitchCase="'text'"
      formControlName="{{ dynamicFormInput.key }}"
      placeholder="{{ dynamicFormInput.placeholder }}"
      [skipTranslate]="true"
    ></app-form-input-text>
  </ng-container>

  <ng-container *ngSwitchCase="'boolean'">
    <app-form-input-checkbox
      formControlName="{{ dynamicFormInput.key }}"
      placeholder="{{ dynamicFormInput.placeholder }}"
    ></app-form-input-checkbox>
  </ng-container>

  <ng-container *ngSwitchCase="'returnCode'">
    <app-return-code-display
      [returnCode]="dynamicFormInput.value"
    ></app-return-code-display>
  </ng-container>
</ng-container>
