<div class="pagination-container">
  <div class="items-per-page">
    <span>{{'general.paginator.ItemsPerPage' | translate}}</span>
    <mat-select panelClass="pagination-panel-class" class="items-per-page-select" [value]="pageSize" [disabled]="disabled" (selectionChange)="onItemsPerPageChange($event)">
      <mat-option *ngFor="let option of _displayedPageSizeOptions" [value]="option">
        {{ option }}
      </mat-option>
    </mat-select>
  </div>

  <div class="pagination-info">
    {{ getStartItem() }} - {{ getEndItem() }} {{'general.paginator.Of' | translate}} {{ length }}
  </div>

  <div class="pagination-controls">
    <button (click)="goToPreviousPage()" [disabled]="disabled || !hasPreviousPage()" class="page-button">
      <i class="ai-icon-chevron-left"></i>
    </button>

    <button (click)="goToNextPage()" [disabled]="disabled || !hasNextPage()" class="page-button">
      <i class="ai-icon-chevron-right"></i>
    </button>
  </div>
</div>
