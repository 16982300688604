<app-panel [formGroup]="parentForm">
  <app-panel-title
    title="careprogram.labels.MainTherapist"
    titleIcon="group"
    [ngClass]="{ 'invalid-title': showWarningMessage }"
  >
    <ng-container panelButtons>
      <button
        *ngIf="!mainTherapistFormArray?.disabled"
        class="c4p-button"
        (click)="addMainTherapist()"
        translate
      >
        general.labels.Add
      </button>
    </ng-container>
  </app-panel-title>

  <ng-container formArrayName="therapists">
    <app-panel-form-row>
      <div class="c4p-font-normal warning" *ngIf="errorMessage">
        {{ '*' + errorMessage }}
      </div>
    </app-panel-form-row>
    <app-panel-form-row
      *ngFor="let control of mainTherapistFormArray.controls; let i = index"
      [formGroupName]="i"
    >
      <app-form-input-dropdown
        placeholder="careprogram.labels.MainTherapist"
        formControlName="employee"
        [dropdownService]="carefileEmployeeService"
        [options]="$any(carefileEmployeeService.values)"
        [search]="true"
        [filterObject]="mainTherapistFilterObject"
        [objectAsValue]="true"
        labelProperty="fullName"
        [selectSingle]="true"
      ></app-form-input-dropdown>
      <app-label
        [labelValue]="{
          label: 'employee.labels.EmployeeNumber',
          value: control.get('employee').value?.employeeNumber
        }"
      ></app-label>
      <app-form-input-date
        formControlName="startDate"
        placeholder="client.labels.StartDate"
        [dateFilter]="dateFilter"
        [maxDate]="control.get('maxStartDate').value"
        [minDate]="control.get('minStartDate').value"
        (openedDateStream)="onOpened(control.get('tempId').value)"
        (closedDateStream)="onClosed()"
        [readOnly]="true"
        [dbclickNull]="true"
        (changeDate)="onchangeDate('startDate')"
      ></app-form-input-date>
      <app-form-input-date
        formControlName="endDate"
        placeholder="client.labels.EndDate"
        [dateFilter]="dateFilter"
        [maxDate]="control.get('maxEndDate').value"
        [minDate]="control.get('startDate').value"
        (openedDateStream)="onOpened(control.get('tempId').value)"
        (closedDateStream)="onClosed()"
        [readOnly]="true"
        [dbclickNull]="true"
        (changeDate)="onchangeDate('endDate')"
      ></app-form-input-date>
      <mat-icon
        *ngIf="!mainTherapistFormArray.disabled"
        matPrefix
        class="c4p-action-icons"
        (click)="deleteMainTherapist(i)"
        >delete</mat-icon
      >
    </app-panel-form-row>
  </ng-container>

  <app-panel-form-row
    *ngIf="mainTherapistFormArray.controls.length === 0"
    rowClass="c4p-empty-table-row"
  >
    <span translate [ngClass]="{ 'invalid-title': showWarningMessage }"
      >general.messages.FormEmpty</span
    >
  </app-panel-form-row>
</app-panel>
