<div class="custom-checkbox-form_{{size}}">
  <label>
    <mat-label >{{title | translate}}</mat-label>
  </label>
  <div class="checkbox-wrapper">
    <mat-checkbox [attr.testId]="testId" [labelPosition]="labelPosition" [formControl]="$any(control)">{{
      (label | translate)?.trim()
    }}@if(control?.hasValidator(requiredTrue)){<span class="required-field">*</span>} 
    </mat-checkbox>
      <mat-hint class="custom-input-hint" [align]="alignHint">{{ (hint ?? '') | translate }}</mat-hint>
      <mat-error>
        @if(control?.errors && control?.touched){
        <app-validation-message
        [errors]="control?.errors"
        ></app-validation-message>
        }
      </mat-error>
      
  </div>
</div>
