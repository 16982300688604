<div class="c4p-form-questionset" style="padding: 2px" [ngStyle]="{'flex-direction' : isSideSheet ? 'column' : ''}">
  <app-panel *ngIf="isSocialAreaVisible">
    <app-table-view
      [options]="tableOptions"
      (onRowClick)="onRowClick($event)"
      (addRequest)="onFilterSocialAreas()"
    ></app-table-view>
  </app-panel>

  <button
    mat-icon-button
    (click)="onToggle()"
    style="width: 0px"
    *ngIf="tabs.length > 0 && !isSideSheet"
  >
    <mat-icon
      class="c4p-top-icons"
      style="
        font-size: 15px;
        height: 20px;
        position: absolute;
        right: -34px;
        top: 57px;
        z-index: 10;
      "
      >menu</mat-icon
    >
  </button>

  <div class="c4p-form-questions-tab" *ngIf="tabs.length > 0">
    <mat-tab-group
      mat-stretch-tabs
      style="background-color: transparent; margin-top: 7px"
      [(selectedIndex)]="selectedIndex"
    >
      <mat-tab *ngFor="let tab of tabs; let index = index">
        <ng-template mat-tab-label>
          <app-collaboration-users
            docIdentifier="{{
              'socialarea' +
                tab.socialArea.id +
                'entity' +
                entityId +
                entity +
                entityVersion
            }}"
          ></app-collaboration-users>
          <div class="col" style="margin-left: 20px">{{ tab.name }}</div>
          <button mat-icon-button (click)="closeTab($event, index)">
            <mat-icon style="font-size: 12px">close</mat-icon>
          </button>
        </ng-template>

        <app-goal
          [data]="tab.socialArea"
          [entityId]="entityId"
          [entity]="entity"
          [entityVersion]="entityVersion"
          [viewMode]="viewMode"
          [scoringEnabled]="scoringEnabled"
          [scoringUnanswered$]="scoringUnanswered$"
          (updateRequest)="updateArea($event)"
          [carefileId]="carefileId"
          [careplan]="careplan"
          [previewMode]="previewMode"
        ></app-goal>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
