import { Component, Input, DoCheck } from '@angular/core';
import { FormControlBase } from '../abstract-form-control';
import { DEFAULT_CHECKBOX_LABEL_POSITION } from '../form-controls.const';

@Component({
  selector: 'app-form-input-checkbox',
  templateUrl: './form-input-checkbox.component.html',
  styleUrls: ['./form-input-checkbox.component.scss'],
})
export class FormInputCheckboxComponent
  extends FormControlBase<FormInputCheckboxComponent>
  implements DoCheck
{
  @Input() labelPosition: "before" | "after" = DEFAULT_CHECKBOX_LABEL_POSITION;
  @Input() width: string = '';

  ngDoCheck(): void {
    this.adjustTouched();
  }
}
