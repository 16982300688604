import { Directive, HostListener, HostBinding } from '@angular/core';

@Directive({
  selector: '[appClickable]',
  standalone: true
})
export class ClickableDirective {
  @HostBinding('attr.tabindex') tabindex = 0;

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.code === 'Enter' || event.code === 'Space') {
      event.preventDefault(); // Prevent default action (e.g., scrolling)
      (event.target as HTMLElement).click(); // Trigger the click event
    }
  }
}