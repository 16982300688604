<ng-container *ngIf="formGroup" [formGroup]="formGroup">
  <div
    class="c4p-dynamic-form-row-group"
    *ngIf="dynamicFormGroup?.formGroupName != ''"
  >
    <div class="c4p-dynamic-form-input-{{ groupLabelStyle }}">
      <span class="c4p-form-label formGroupName">{{
        dynamicFormGroup?.formGroupName
        }}</span>
      <mat-checkbox
        *ngIf="!showParentStyle"
        formControlName="allowAllPermissions"
        (change)="onTouchedCallback($event)"
      ></mat-checkbox>
      
    </div>
  </div>

  <div class="c4p-dynamic-form-row">
    <ng-container
      *ngFor="let dynamicEntry of dynamicFormGroup?.controls | keyvalue"
    >
      <ng-container [ngSwitch]="dynamicEntry.value.dynamicType">
        <ng-container *ngSwitchCase="'group'">
          <app-panel-form-row style="width: 100%">
            <app-dynamic-form-group
              [showParentStyle]="showParentStyle"
              [style]="getParentStyle()"
              [dynamicFormGroup]="$any(dynamicEntry).value"
              [formGroup]="$any(formGroup).get(dynamicEntry.key)"
              [groupLabelStyle]="groupLabelStyle"
              [showAddRemoveButtons]="showAddRemoveButtons"
              [showAddButton]="showAddButton"
              [showRemoveButton]="showRemoveButton"
              [showRemoveButtonOverride]="showRemoveButtonOverride"
              [showRemoveButtonLabel]="showRemoveButtonLabel"
              [includeAllowAllPermissions]="includeAllowAllPermissions"
            ></app-dynamic-form-group>
          </app-panel-form-row>
        </ng-container>
        <ng-container *ngSwitchCase="'array'">
          <app-dynamic-form-array
          [showParentStyle]="showParentStyle"
          [style]="getParentStyle()"
            [dynamicFormArray]="$any(dynamicEntry).value"
            [formGroup]="formGroup"
            [groupLabelStyle]="groupLabelStyle"
            [showAddRemoveButtons]="showAddRemoveButtons"
            [showAddButton]="showAddButton"
            [showRemoveButton]="showRemoveButton"
            [showRemoveButtonOverride]="showRemoveButtonOverride"
            [showRemoveButtonLabel]="showRemoveButtonLabel"
            [includeAllowAllPermissions]="includeAllowAllPermissions"
          ></app-dynamic-form-array>
        </ng-container>
        <ng-container *ngSwitchCase="'control'">
          <app-dynamic-form-control
            *ngIf="dynamicEntry.key !== 'allowAllPermissions'"
            [dynamicFormInput]="$any(dynamicEntry).value"
            [formGroup]="formGroup"
            [groupLabelStyle]="groupLabelStyle"
            [showAddRemoveButtons]="showAddRemoveButtons"
          ></app-dynamic-form-control>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="formGroupName" [formGroupName]="formGroupName">
  <div>
    <ng-container
      *ngFor="let dynamicEntry of dynamicFormGroup?.controls | keyvalue"
    >
      <ng-container [ngSwitch]="dynamicEntry.value.dynamicType">
        <ng-container *ngSwitchCase="'group'">
          <app-dynamic-form-group
            [style]="getParentStyle()"
            [showParentStyle]="showParentStyle"
            [dynamicFormGroup]="$any(dynamicEntry).value"
            [formGroup]="$any(formGroup).get(formGroupName).get(dynamicEntry.key)"
            [groupLabelStyle]="groupLabelStyle"
            [showAddRemoveButtons]="showAddRemoveButtons"
            [includeAllowAllPermissions]="includeAllowAllPermissions"
          ></app-dynamic-form-group>
        </ng-container>
        <ng-container *ngSwitchCase="'array'">
          <app-dynamic-form-array
          [style]="getParentStyle()"
          [showParentStyle]="showParentStyle"
            [dynamicFormArray]="$any(dynamicEntry).value"
            [formGroup]="formGroup"
            [groupLabelStyle]="groupLabelStyle"
            [showAddRemoveButtons]="showAddRemoveButtons"
            [includeAllowAllPermissions]="includeAllowAllPermissions"
          ></app-dynamic-form-array>
        </ng-container>
        <ng-container *ngSwitchCase="'control'">
          <app-dynamic-form-control
          
            [dynamicFormInput]="$any(dynamicEntry).value"
            [formGroup]="formGroup"
            [groupLabelStyle]="groupLabelStyle"
            [showAddRemoveButtons]="showAddRemoveButtons"
          ></app-dynamic-form-control>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
