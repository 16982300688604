<div class="radio-group-container_{{ size() }}" [style]="radioStyleWrapperContainer()">
  <label>
    <mat-label id="radio-group-label" [style]="labelStyle()">{{label() | translate}}@if(control?.hasValidator(required)){<span class="required-field">*</span>}</mat-label>
  </label>
  @if (groupHint) {
    <mat-hint class="custom-input-hint">{{ groupHint | translate }}</mat-hint>
    }
  <mat-radio-group

    [style]="radioGroupContainer()"
    aria-labelledby="radio-group-label"
    [class.vertical]="direction()==='column'"
    [class.horizontal]="direction()==='row'"
    name="{{ controlName() }}"
    [formControl]="$any(control)"
  >
    @for (option of options(); track $index) {
    <mat-radio-button [style]="radioButtonStyle()" class="{{($index!==0 && direction()==='row') ? 'left-spaced' :''}}" [disableRipple]="true" [value]="option.value">
      @if(radioLabelTemplate){
        <ng-container *ngTemplateOutlet="radioLabelTemplate; context: { option: option, index: $index}"> </ng-container>
      }@else{
        {{ option.label | translate }}
      }
    </mat-radio-button>
    }
  </mat-radio-group>
  <mat-error>
    @if(control?.errors && control?.touched){
    <app-validation-message
    [errors]="control.errors"
    ></app-validation-message>
    }
  </mat-error>
</div>
