import {
  Component,
  OnInit,
  ViewChild,
  Input,
  AfterViewInit,
  OnDestroy,
  ElementRef,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  AfterViewChecked,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  pairwise,
  skip,
  startWith,
  takeUntil,
} from 'rxjs/operators';
import {
  AnswerModel,
  AnswerTypeConfigurationModel,
  QuestionSetAnswerListModel,
} from '../../../models';
import { FormatCleanerService, PermissionService, UserInfoService, QuestionSetRenderService,
  DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT,
  IFormDropdownOption
} from 'c4p-portal-util';
import { fadeAnimation } from '../../../general-components';
import {
  ExpandInfo,
  QuestionExpandComponent,
} from '../question-expand/question-expand.component';
import { QuestionRenderHelper } from './question-render.helper';

@Component({
  selector: 'app-question-render',
  templateUrl: './question-render.component.html',
  styleUrls: ['./question-render.component.scss'],
  animations: [fadeAnimation],
})
export class QuestionRenderComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges, AfterViewChecked
{
  @Input() data: any = {};
  @Input() entityId: string | number;
  @Input() entity: string;
  @Input() entityVersion: number;
  @Input() isEditable? = true;
  @Input() isClientPortal? = false;
  @Input() formatUnanswered$ = new Subject<void>();
  @Input() isEscalated: boolean = false;
  @Input() isSideSheet: boolean = false;
  @Output() refreshQuestionSet = new EventEmitter();

  public focusRichText = false;
  fixedOffsetHeight = 30;

  private questionRenderHelper = new QuestionRenderHelper(this.formBuilder);

  private readonly destroyed$ = new Subject<boolean>();
  contentHeight: number;
  @ViewChild('scroll', { read: ElementRef }) public scroll: ElementRef<any>;

  isFormatVisible: boolean = true;
  hasPermission: boolean;

  questionSetAnswerListItem: QuestionSetAnswerListModel;
  questionSetAnswerId: string | number;
  currentQuestionSet: QuestionSetAnswerListModel =
    new QuestionSetAnswerListModel();
  answers: AnswerModel[] = [];

  questionSet$ = new BehaviorSubject<any>(null);
  answersFormArray = null!;
  optionsForChoice: [options: AnswerTypeConfigurationModel[]];

  answerSubscription: Subscription;

  filterForm = new FormGroup({
    answeredFilter: new FormControl(1),
    mandatoryFilter: new FormControl(false),
  });

  dropdownOptions: IFormDropdownOption[] = [
    { label: 'general.labels.All', value: 1 },
    { label: 'questionset.labels.Unanswered', value: 2 },
  ];

  verticalOffset: number = 0;

  constructor(
    public questionSetRenderService: QuestionSetRenderService,
    public formBuilder: FormBuilder,
    public dialog: MatDialog,
    public permissionService: PermissionService,
    public formatCleanerService: FormatCleanerService,
    private userInfoService: UserInfoService,
  ) {}

  onToggle(): void {
    this.isFormatVisible = !this.isFormatVisible;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isEditable: boolean = changes.isEditable?.currentValue;
    if (isEditable) {
      this.answersFormArray?.enable();
    } else {
      this.answersFormArray?.disable();
    }

    if (changes.entityVersion) {
      this.entityVersion = changes.entityVersion.currentValue;
    }
  }

  ngOnInit(): void {
    this.answersFormArray = this.formBuilder.array([]);
    this.currentQuestionSet = this.data;
    this.questionSetAnswerId = this.currentQuestionSet.id;
    if (this.entity === 'report') {
      this.hasPermission =
        this.isEscalated ||
        this.currentQuestionSet.roles.readWrite?.includes(
          this.permissionService.currentRole,
        ) ||
        this.currentQuestionSet.roles.read?.includes(
          this.permissionService.currentRole,
        );
    } else if (this.entity === 'careprogram') {
      this.hasPermission =
        (this.isEscalated ||
          this.currentQuestionSet.roles.read?.includes(
            this.permissionService.currentRole,
          ) ||
          this.currentQuestionSet.roles.readWrite?.includes(
            this.permissionService.currentRole,
          )) &&
        this.permissionService.getPermission('careprogram:zpm:carefile:full');
      this.isEditable = this.currentQuestionSet.roles.readWrite?.includes(
        this.permissionService.currentRole,
      );
    } else {
      this.hasPermission =
        this.isEscalated ||
        this.currentQuestionSet.roles.read?.includes(
          this.permissionService.currentRole,
        ) ||
        this.currentQuestionSet.roles.readWrite?.includes(
          this.permissionService.currentRole,
        );
    }
    if (this.userInfoService.isClientPortal) this.hasPermission = true;
    if (this.hasPermission) {
      if (this.isEscalated || this.isEditable) {
        this.answersFormArray.enable();
      } else {
        this.answersFormArray.disable();
      }

      if (!this.isEditable) {
        this.answersFormArray.disable();
      }

      this.refreshAnswers(this.questionSetAnswerId);
    }
    this.formatUnanswered$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.answersFormArray.markAllAsTouched());
  }

  ngAfterViewInit(): void {
    this.filterForm.valueChanges.subscribe(() => {
      this.filterAnswers();
    });
  }

  ngAfterViewChecked() {
    if (this.scroll) {
      this.scroll.nativeElement.scrollTop = this.verticalOffset;
    }
  }

  onTabHandler(offsetHeight: number) {
    this.verticalOffset += offsetHeight + this.fixedOffsetHeight;
  }

  onTabRichEditor(element: HTMLElement) {
    if (element === document.activeElement) {
      this.onTabHandler(element.offsetHeight);
    }
  }

  ngOnDestroy(): void {
    if (this.answerSubscription) this.answerSubscription.unsubscribe();

    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public addAnswer(answer?: AnswerModel) {
    const answerFormGroup =
      this.questionRenderHelper.buildAnswersFormGroup(answer);
    answerFormGroup.markAsDirty();
    const options = [];
    for (let option of answer.typeConfiguration) {
      options.push({ label: option.displayText, value: option._id });
    }
    this.optionsForChoice[answer.id] = options;
    answerFormGroup.valueChanges
      .pipe(
        skip(this.userInfoService.isClientPortal ? 2 : 1),
        debounceTime(DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT),
        startWith(null as string),
        pairwise(),
      )
      .subscribe(([prevAnswer, answer]) => {
        answer.isAnswered = false;
        if (answer.type === 4 && answer.answer.includes('true')) {
          answer.isAnswered = true;
        } else if (answer.type !== 4 && answer.answer !== '') {
          answer.isAnswered = true;
        }
        answer.entityType = this.entity;
        answer.entityVersion = this.entityVersion;
        if (this.isEditable && answer.type === 7) {
          if (!answer.answer && answer.isMandatory) {
            return;
          }
          this.questionSetRenderService.updateAnswer(answer).subscribe(() => {
            this.refreshQuestionSet.emit();
          });
        } else if (this.isEditable && answer.type !== 3) {
          this.questionSetRenderService.updateAnswer(answer).subscribe(() => {
            this.refreshQuestionSet.emit();
          });
        } else if (
          this.isEditable &&
          (prevAnswer || answer.answer) &&
          prevAnswer?.answer !== answer.answer
        ) {
          this.questionSetRenderService.updateAnswer(answer).subscribe(() => {
            this.refreshQuestionSet.emit();
          });
        }
      });
    if (this.isEditable) {
      answerFormGroup.enable();
    } else {
      answerFormGroup.disable();
    }
    this.answersFormArray.push(answerFormGroup);
  }

  refreshAnswers(questionSetAnswerId: string | number): void {
    if (this.answerSubscription) this.answerSubscription.unsubscribe();
    this.answersFormArray.clear();
    this.optionsForChoice = [[]];
    this.questionSetRenderService
      .getAnswers(
        this.entity,
        this.entityId,
        this.entityVersion,
        questionSetAnswerId,
        this.filterForm.value,
      )
      .subscribe((data) => {
        for (let answer of data.docs) {
          this.addAnswer(answer);
        }

        this.answerSubscription = this.answersFormArray.valueChanges
          .pipe(debounceTime(DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT))
          .subscribe((x) => {
            //TODO left for future use
          });
        if (
          this.isEscalated ||
          this.currentQuestionSet.roles.readWrite.includes(
            this.permissionService.currentRole,
          )
        ) {
        } else if (
          this.isEscalated ||
          this.currentQuestionSet.roles.read?.includes(
            this.permissionService.currentRole,
          ) ||
          this.userInfoService.isClientPortal
        )
          this.answersFormArray.disable();
        else this.answersFormArray.clear();
      });
  }

  filterAnswers() {
    if (this.currentQuestionSet.title)
      this.refreshAnswers(this.questionSetAnswerId);
  }

  onEditorCreated(event) {
    event.focus();
    event.clipboard.addMatcher(Node.ELEMENT_NODE, function (node, delta) {
      let ops = [];
      delta.ops.forEach((op) => {
        if (op.insert && typeof op.insert === 'string') {
          ops.push({
            insert: op.insert,
          });
        }
      });
      delta.ops = ops;
      return delta;
    });
  }

  onRichContent(event, answerGroup: FormGroup) {
    event?.editor
      ?.getModule('toolbar')
      .container.addEventListener('mousedown', (e) => {
        e.preventDefault(); // to prevent focus out on clicking toolbar
      });
    if (!event.range && event.oldRange !== null) {
      // on blur
      if (this.answersFormArray.disabled)
        answerGroup.controls.focusRichText.patchValue(false);
      else
        answerGroup.controls.focusRichText.patchValue(
          !answerGroup.value.focusRichText,
        );
      // if (this.answersFormArray.disabled) this.focusRichText = false;
      // else this.focusRichText = !this.focusRichText;
    }
  }

  onScroll(event) {
    this.verticalOffset = this.scroll.nativeElement.scrollTop;
  }

  expand(controlIndex: number) {
    const richTextControl = this.answersFormArray.at(controlIndex);
    const richTextValue = richTextControl.value;

    const data: ExpandInfo = {
      hint: richTextValue.hint,
      orderNum: richTextValue.order,
      placeholder: richTextValue.title,
      disabled: richTextControl.disabled,
      required: richTextValue.isMandatory,
      isClientPortal: this.isClientPortal,
      value: richTextValue.answer,
      type: 'richtext',
    };

    const dialogRef = this.dialog.open(QuestionExpandComponent, { data });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        richTextControl.get('answer').setValue(result);
        this.answersFormArray.updateValueAndValidity();
      }
    });
  }
}
