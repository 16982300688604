import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hour',
  standalone:true
})
export class HourPipe implements PipeTransform {
  transform(value: number): string {
    return value ? this.MMtoHHMM(value) : '';
  }

  private MMtoHHMM(value: number): string {
    const hh = Math.floor(value / 60);
    const mm = value - hh * 60;
    return `${hh}: ${mm < 10 ? '0' : ''}${mm}`;
  }
}