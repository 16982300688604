import { NgModule } from "@angular/core";
import { SideSheetComponent, TuulaTableActionButtonComponent, TuulaTableActionColumnComponent, TuulaTableComponent, TuulaTableFilterHeaderColumnComponent, TuulaTableSettingsComponent, TuulaTableSkeletonComponent } from "tuula-common";

const moduleComponents = [
    TuulaTableComponent,
    SideSheetComponent,
    TuulaTableActionButtonComponent,
    TuulaTableActionColumnComponent,
    TuulaTableFilterHeaderColumnComponent,
    TuulaTableSettingsComponent,
    TuulaTableSkeletonComponent
]

@NgModule({
  imports: [...moduleComponents],
  bootstrap: [],
  declarations: [],
  exports: [...moduleComponents],
  providers: [],
  schemas: [],
})
export class ComponentsModule {}