import { ActionType } from "tuula-common";


export class MedicationProfileModel  {
  tenantId?: string;
  clientExternalId?: string;
  clientId?: string;
  status?: any;
  action?: ActionType;
  errorMessage?: string | null;
  errorCode?: string | null;
  medicationProfile?: boolean;
  createdAt?: Date;
  updatedAt?: Date;

}

