import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls/form-controls.const';
import { MasterDataService } from '../../master-data/master-data.service';
import { map, of, pipe } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PerformanceTypeService {
  public values: IFormDropdownOption[] = [];
  public lookupValues: string[] = [];

  constructor(private masterDataService: MasterDataService) {
    this.getPerformanceTypes().subscribe();
  }

  public getPerformanceTypes() {
    return this.masterDataService
      .getMasterData('performance-type')
      .pipe(map( data => {
        this.values = data;
        for (let item of this.values) {
          this.lookupValues[item.value] = item.label;
        }
        return data;
      }));
  }


}
