<app-panel type="popup n-row">
  <div [formGroup]="form">
    <app-panel-form-row
      formArrayName="subjects"
      *ngFor="let sub of subsFormArray.controls; let i = index"
    >
      <ng-container [formGroupName]="i">
        <mat-checkbox [formControl]="$any(sub)">{{
          'notification.titles.' + subsData[i].name | translate
        }}</mat-checkbox>
      </ng-container>
    </app-panel-form-row>
  </div>

  <div *ngIf="loading" class="spinner-item">
    <mat-progress-spinner
      [mode]="'indeterminate'"
      [diameter]="25"
    ></mat-progress-spinner>
  </div>

</app-panel>
