import { MatDateFormats } from '@angular/material/core';
import { NgxMatDateFormats } from '@angular-material-components/datetime-picker';
import * as moment_ from 'moment';
const moment = moment_;

export type BackendServiceType =
  | 'clients'
  | 'tenants'
  | 'applications'
  | 'client-network'
  | 'employees'
  | 'teams'
  | 'contact-persons';

// forms
export const DEFAULT_CONTROL_DEBOUNCE_TIME_AMOUNT = 100;
export const DEFAULT_VALUE_CHANGES_DEBOUNCE_TIME_AMOUNT = 150;
export const DEFAULT_AUTOCOMPLETE_DEBOUNCE_TIME_AMOUNT = 500;
export const DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT = 1000;
export const DEFAULT_LONG_REQUEST_DEBOUNCE_TIME_AMOUNT = 2000;

// abstract
export const DEFAULT_PLACEHOLDER = '';
export const DEFAULT_ERRORS_MAP = new Map([
  ['required', 'general.form.Mandatory'],
  ['email', 'general.form.InvalidEmail'],
  ['min', 'general.form.InvalidMin'],
  ['max', 'general.form.InvalidMax'],
  ['minlength', 'general.form.InvalidMinLength'],
  ['maxlength', 'general.form.InvalidMaxLength'],
  ['duplicate', 'general.form.InvalidDuplicate'],
  ['dividedby5', 'general.form.dividedby5'],
  ['pattern', 'general.form.InvalidPattern'],
  ['matDatepickerParse', 'general.form.InvalidDate'],
  ['matDatepickerMin', 'general.form.InvalidDateMin'],
  ['matDatepickerMax', 'general.form.InvalidDateMax'],
  ['matDatetimePickerParse', 'general.form.InvalidDateTime'],
  ['matDatetimePickerMin', 'general.form.InvalidDateTimeMin'],
  ['matDatetimePickerMax', 'general.form.InvalidDateTimeMin'],
  ['invalidTime', 'general.form.InvalidTime'],
  ['invalidTimeMin', 'general.form.InvalidTimeMin'],
  ['invalidTimeMax', 'general.form.InvalidTimeMax'],
  ['modulo', 'general.form.InvalidModulo'],
  ['invalidMinHour', 'general.form.InvalidMinHour'],
  ['invalidMaxHour', 'general.form.InvalidMaxHour'],
  ['urlValidator', 'general.form.SystemUrlMustStartWithHttp'],
  ['BsnInUse', 'general.form.BsnInUse'],
  ['organizationCode', 'general.form.InvalidOrganizationCode'],
  ['emailTaken', 'general.validation.emailTaken'],
  ['bsnValidator', 'boilerplate.validation.BsnValidator'],
  ['closingDateInvalid', 'hours.labels.Not_all_hours_final'],
  ['notUnique', 'productCatalog.validation.UniqueServiceBudget'],
  ['nameExists', 'team.validation.TeamNameExists'],
  ['subdomainExists', 'tenant.validation.SubdomainExists'],
  ['vatNumber', 'general.form.InvalidKVKNumber'],
  ['noMatch', 'general.labels.PasswordsDoNotMatch'],
  ['bsnValidator', 'boilerplate.validation.BsnValidator'],
]);
export const DEFAULT_DATEPICKER_ERRORS_ARRAY = [
  'matDatepickerParse',
  'matDatepickerMin',
  'matDatepickerMax',
  'matDatetimePickerParse',
  'matDatetimePickerMin',
  'matDatetimePickerMax',
];

// text
export const DEFAULT_INPUT_TYPE = 'text';

// textarea
export const DEFAULT_TEXT_AREA_TYPE = 'text';
export const DEFAULT_TEXT_AREA_ROWS = 1;
export const DEFAULT_TEXT_AREA_COLUMNS = 10;
export const DEFAULT_TEXT_AREA_DISABLE_RESIZE = false;
export const DEFAULT_TEXT_AREA_LARGE = false;
export const DEFAULT_AUTO_RESIZE = true;

// checkbox
export const DEFAULT_CHECKBOX_LABEL_POSITION = 'after';

// dropdown
export const DEFAULT_DROPDOWN_SHOW_BLANK = true;
export interface IFormDropdownOption {
  label: string;
  value: any;

  disabled?: boolean;
  type?: string;
  percentage?: number;
  from?: number;
  to?: number;
  startDate?: Date;
  endDate?: Date;
  translation?: string;
  checked?: boolean;

  permission?: string;
  permissionOperator?: 'or' | 'and';

  hint?: string;
}

export interface Option {
  label: string;
  value: any;

  disabled?: boolean;
  hint?: string;
}

interface itemType {
  type: string;
  iconName: string;
}

export interface IFormListboxOption {
  id: string;
  item: string;
  date?: Date;
  title?: string;
  bold?: boolean;
  type?: itemType;
}

// radio
export const DEFAULT_RADIO_DIRECTION = 'row';

export const COLUMN_RADIO_DIRECTION = 'column';

// date
export const UTC_TIMEZONE = 'Africa/Accra';
export const USE_UTC = false;
export const USE_UTC_DATE = true;
export const DEFAULT_MOMENT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DATE_INPUT_FORMAT = 'DD/MM/YYYY';


export const DEFAULT_MIN_DATE_ND = new Date("1900-01-01");
export const DEFAULT_MAX_DATE_ND = new Date("2999-12-31");



export const DEFAULT_MIN_DATE: moment.Moment = moment(
  '01/01/1900',
  'DD/MM/YYYY',
);

export const DEFAULT_MIN_DATE_TIME: moment.Moment = moment(
  '01/01/1900 00:00',
  'DD/MM/YYYY HH:mm',
);

export const DEFAULT_MAX_DATE_TIME: moment.Moment = moment(
  '31/12/2999 23:59',
  'DD/MM/YYYY HH:mm',
);

export const DEFAULT_MAX_DATE: moment.Moment = moment(
  '31/12/2999',
  'DD/MM/YYYY',
);
export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export const APP_DATE_FORMATS_ND: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMM YYYY',
  },
};

export const DATE_REGEX = (event) => {
  var v = event.currentTarget.value;
  var keyCode = event.keyCode || event.charCode;

  // skip non numeric
  if (
    event.key?.length === 1 &&
    /\D/.test(event.key)
    //&& event.key !== "/"
  ) {
    event.preventDefault();
    return false;
  }

  if (!(keyCode == 8 || keyCode == 46) && event.key !== '/') {
    if (v.match(/^\d{2}$/) !== null) {
      event.currentTarget.value = v + '/';
    } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
      event.currentTarget.value = v + '/';
    }
  } else {
    if (
      v.match(/^\d{2}\/\d{1}$/) !== null ||
      v.match(/^\d{2}\/\d{2}\/\d{1}$/) !== null
    ) {
      event.currentTarget.value = v.slice(0, -1);
    }
  }
};

export const DATE_MONTH_YEAR_REGEX = (event: KeyboardEvent) => {
  const inputElement = event.currentTarget as HTMLInputElement;
  let value = inputElement.value;
  const key = event.key;

  // Allow only numeric input and `/`
  if (key.length === 1 && /\D/.test(key) && key !== "/") {
    event.preventDefault();
    return false;
  }

  // Add a slash after two digits (month)
  if (!(key === "Backspace" || key === "Delete" || key === "/")) {
    if (value.match(/^\d{2}$/) !== null) {
      inputElement.value = value + "/";
    }
  }

  // Handle backspace or deletion to maintain valid format
  if ((key === "Backspace" || key === "Delete") && value.match(/^\d{2}\/\d{1,5}$/) !== null) {
    inputElement.value = value.slice(0, -1);
  }

  // Limit input length to 7 characters (MM/YYYY)
  if (value.length > 7) {
    inputElement.value = value.slice(0, 7);
    event.preventDefault();
  }

  // Ensure the year part does not exceed 4 digits
  const parts = value.split("/");
  if (parts.length === 2 && parts[1]?.length > 4) {
    event.preventDefault(); // Prevent further input
    inputElement.value = `${parts[0]}/${parts[1].slice(0, 4)}`;
  }

  // Validate the complete input as MM/YYYY format
  if (!value.match(/^((0?[1-9])|(1[0-2]))\/(\d{4})$/) && value.length === 7) {
    event.preventDefault();
  }
};


//time
export const DEFAULT_TIME_SHOW_SPINNERS = false;
export const DEFAULT_TIME_SHOW_SECONDS = false;
export const DEFAULT_TIME_HOUR_STEP = 1;
export const DEFAULT_TIME_MINUTE_STEP = 1;
function replaceBadInputs(val) {
  // Replace impossible inputs as they appear
  // val = val.replace(/[^\dh:]/, '');
  // val = val.replace(/^[^0-2]/, '');
  // val = val.replace(/^([2-9])[4-9]/, '$1');
  // val = val.replace(/^\d[:h]/, '');
  // val = val.replace(/^([01][0-9])[^:h]/, '$1');
  // val = val.replace(/^(2[0-3])[^:h]/, '$1');
  // val = val.replace(/^(\d{2}[:h])[^0-5]/, '$1');
  // val = val.replace(/^(\d{2}h)./, '$1');
  // val = val.replace(/^(\d{2}:[0-5])[^0-9]/, '$1');
  // val = val.replace(/^(\d{2}:\d[0-9])./, '$1');

  val = val.replace(/^[^0-2]/, '');
  val = val.replace(/^([2-9])[4-9]/, '$1');
  val = val.replace(/^(\d{2}:[0-5])[^0-9]/, '$1');
  val = val.replace(/^(\d{2}:\d[0-9])./, '$1');
  return val;
}

export const TIME_REGEX = (event) => {
  var v = event.currentTarget.value;
  var keyCode = event.keyCode || event.charCode;

  // skip non numeric
  if (
    event.key.length === 1 &&
    /\D/.test(event.key)
    // &&
    // event.key !== "/" &&
    // event.key !== ":" &&
    // event.key !== " "
  ) {
    event.preventDefault();
    return false;
  }

  let inputModified = false;
  if (!(keyCode == 8 || keyCode == 46)) {
    if (v.match(/^\d{2}$/) !== null) {
      event.currentTarget.value = v + ':';
      inputModified = true;
    }
  } else {
    if (v.match(/^\d{2}:$/) !== null) {
      event.currentTarget.value = v.slice(0, -1);
      inputModified = true;
    }
  }

  if (!inputModified) {
    var val = v;
    var lastLength;
    do {
      // Loop over the input to apply rules repeately to pasted inputs
      lastLength = val.length;
      val = replaceBadInputs(val);
    } while (val.length > 0 && lastLength !== val.length);
    event.currentTarget.value = val;
  }
};

export const HOUR_REGEX = (event) => {
  const keyCode = event.keyCode || event.charCode;
  const input = event.currentTarget;
  const value = input.value;
  const enteredChar = String.fromCharCode(keyCode); // Get the entered character
  const hourRegex = /^([0-9]|0[0-9]|1[0-9]|2[0-3])$/;

  // Allow navigation and control keys
  if (
    keyCode === 8 || // Backspace
    keyCode === 46 || // Delete
    keyCode === 37 || // Left arrow
    keyCode === 39 || // Right arrow
    keyCode === 9 // Tab
  ) {
    return true;
  }

  // Combine current value with entered character
  let newValue = value + enteredChar;
  if (value === '00') {
    // Replace '00' with the entered character and format as a 2-digit string
    input.value = `0${enteredChar}`;
    return hourRegex.test(input.value); // Validate the final value (should be 02, 03, ..., 09)
  }
  // If it's a single-digit number between 0-9, add a leading zero
  // if (/^[0-9]$/.test(newValue)) {
  //   newValue = `0${newValue}`;
  //   input.value = newValue; // Update the input field with the padded value
  //   event.preventDefault(); // Prevent the default behavior of adding the character again
  //   return false;
  // }
  // Validate the final combined value
  return hourRegex.test(newValue);
};

export const MINUTE_REGEX = (event) => {
  const keyCode = event.keyCode || event.charCode;
  const input = event.currentTarget;
  const value = input.value;
  const enteredChar = String.fromCharCode(keyCode); // Get the entered character
  const minuteRegex = /^[0-5]?[0-9]$/;

  // Allow navigation and control keys (Backspace, Delete, Left Arrow, Right Arrow, Tab)
  if (
    keyCode === 8 || // Backspace
    keyCode === 46 || // Delete
    keyCode === 37 || // Left arrow
    keyCode === 39 || // Right arrow
    keyCode === 9 // Tab
  ) {
    return true;
  }

  // Case: If the input is '00', change it to '0' and append the entered digit
  if (value === '00') {
    // Replace '00' with the entered character and format as a 2-digit string
    input.value = `0${enteredChar}`;
    return minuteRegex.test(input.value); // Validate the final value (should be 02, 03, ..., 09)
  }

  // Combine current value with entered character
  let newValue = value + enteredChar;

  // Validate the combined value (should be a valid minute, 00-59)
  return minuteRegex.test(newValue);
};








// datetime
export const DEFAULT_DATE_TIME_SHOW_SPINNERS = true;
export const DEFAULT_DATE_TIME_SHOW_SECONDS = false;
export const DEFAULT_DATE_TIME_HOUR_STEP = 1;
export const DEFAULT_DATE_TIME_MINUTE_STEP = 1;
export const APP_DATE_TIME_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY HH:mm',
  },
  display: {
    dateInput: 'DD/MM/YYYY HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export const YEAR_MONTH_FORMATS_ND = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export const DATE_TIME_REGEX = (event) => {
  var v = event.currentTarget.value;
  var keyCode = event.keyCode || event.charCode;
  // skip non numeric
  if (
    event.key.length === 1 &&
    /\D/.test(event.key)
    // &&
    // event.key !== "/" &&
    // event.key !== ":" &&
    // event.key !== " "
  ) {
    event.preventDefault();
    return false;
  }

  if (
    !(keyCode == 8 || keyCode == 46)
    // &&
    // event.key !== "/" &&
    // event.key !== ":" &&
    // event.key !== " "
  ) {
    if (v.match(/^\d{2}$/) !== null) {
      event.currentTarget.value = v + '/';
    } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
      event.currentTarget.value = v + '/';
    } else if (v.match(/^\d{2}\/\d{2}\/\d{4}$/) !== null) {
      event.currentTarget.value = v + ' ';
    } else if (v.match(/^\d{2}\/\d{2}\/\d{4}\s\d{2}$/)) {
      event.currentTarget.value = v + ':';
    }
  } else {
    if (
      v.match(/^\d{2}\/\d{1}$/) !== null ||
      v.match(/^\d{2}\/\d{2}\/\d{1}$/) !== null ||
      v.match(/^\d{2}\/\d{2}\/\d{4}\s\d{1}$/) !== null ||
      v.match(/^\d{2}\/\d{2}\/\d{4}\s\d{2}:\d{1}$/) !== null
    ) {
      event.currentTarget.value = v.slice(0, -1);
    }
  }
};

// daterange
export const DEFAULT_START_DATE_PLACEHOLDER = 'employee.labels.StartDate';
export const DEFAULT_END_DATE_PLACEHOLDER = 'employee.labels.EndDate';

// froala editor settings
export const DEFAULT_QUILL_SETTINGS = {};

//patterns
export const PATTERN = {
  string: "^['A-Za-zÀ-ž-\\s]*$",
  stringDot: "^['A-Za-zÀ-ž-.\\s]*$",
  text: "^['A-Za-zÀ-ž-.,!?()0-9\\s]*$",
  numbersOnly: '^[0-9]*$',
  distance: '^[0-9]{0,3}$',
  email: '^[A-Za-z0-9._%+-]+@[A-Za-z0-9-]+(\\.[A-Za-z]{2,})+$',
  initials: "^['A-Za-zÀ-ž.\\s]*$",
  stringAndNumbers: "^['A-Za-zÀ-ž0-9-\\s]*$",
  phone: '^[0-9\\s]*$',
  phoneWithCharacters: '^[+]{0,1}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s.0-9]*$',
  employeeBIG: '^[0-9]{1,11}$',
  postalCode: '^[0-9]{4}[A-Z]{1,2}$',
  decimalAndNumber: '^[+-]?([0-9]+.?[0-9]*|.[0-9]+)$',
  digits: '^-?[0-9]\\d*(\\.\\d{1,2})?$',
  wholeNumber: '^(0|[1-9][0-9]*)$',
  vat: '^NL\\d{9}[A-Z]\\d{2}$',
  iban: '^NL\\d{2}[A-Z]{4}\\d{10}$',
  phoneNumber: '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$',
  password:
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*d)(?=.*[@$!%*?&])[A-Za-zd@$!%*?&]{8,}$',
  phoneComp: '^[0-9s]*$',
};

export const AGB_LEADING_ZEROS = '00000000';
