<app-panel [formGroup]="addressForm" type="{{ inline ? '' : 'popup' }}">
  <app-panel-title
    [title]="
      data?.address?.id || data?.address?.existing
        ? 'general.titles.EditAddress'
        : 'general.titles.AddAddress'
    "
  ></app-panel-title>

  <app-panel-form-row>
    <app-form-input-dropdown
      formControlName="addressType"
      placeholder="employee.labels.AddressType"
      [options]="addressTypeService.values"
    >
    </app-form-input-dropdown>
  </app-panel-form-row>

  <app-panel-form-row *ngIf="addressForm.get('addressType').value == '04'">
    <app-form-input-date
      formControlName="startDate"
      placeholder="employee.labels.StartDate"
    ></app-form-input-date>
    <app-form-input-date
      formControlName="endDate"
      placeholder="employee.labels.EndDate"
    ></app-form-input-date>
  </app-panel-form-row>

  <app-panel-form-row>
    <app-form-input-text
      formControlName="postalCode"
      placeholder="employee.labels.PostCode"
      [addresscase]="true"
    >
    </app-form-input-text>

    <app-form-input-text
      formControlName="houseNumber"
      placeholder="employee.labels.HouseNumber"
      type="number"
    >
    </app-form-input-text>

    <app-form-input-text
      formControlName="streetName"
      placeholder="employee.labels.StreetName"
    >
    </app-form-input-text>
  </app-panel-form-row>

  <app-panel-form-row>
    <app-form-input-text
      formControlName="houseLetter"
      placeholder="employee.labels.HouseLetter"
    >
    </app-form-input-text>

    <app-form-input-text
      formControlName="addition"
      placeholder="employee.labels.NumberAddendum"
    >
    </app-form-input-text>

    <app-form-input-dropdown
      formControlName="designation"
      placeholder="employee.labels.NumberDesignation"
      [options]="numberDesignationService.values"
    ></app-form-input-dropdown>
  </app-panel-form-row>

  <app-panel-form-row>
    <app-form-input-text
      formControlName="city"
      placeholder="employee.labels.City"
    >
    </app-form-input-text>

    <app-form-input-text
      formControlName="municipality"
      placeholder="employee.labels.Municipality"
    >
    </app-form-input-text>

    <app-form-input-text
      formControlName="country"
      placeholder="employee.labels.Country"
    >
    </app-form-input-text>
  </app-panel-form-row>
</app-panel>

<div *ngIf="!inline" style="display: flex">
  <span class="c4p-button-cancel" (click)="onDismiss()" translate
    >general.labels.Cancel</span
  >
  <span class="c4p-button-ok" (click)="onConfirm()">
    <span>OK</span>
  </span>
</div>
