import { NgModule, ModuleWithProviders } from '@angular/core';
import { MaterialModule } from './material.module';
import { FormControlsModule } from './lib/form-controls';
import { GeneralComponentsModule } from './lib/general-components';
import { DirectivesModule } from './lib/directives';
import { PipesModule } from './lib/pipes';
import { SharedFormsModule } from './lib/shared-forms';
import { DynamicFormsModule } from './lib/dynamic-forms';
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';

import {
  APP_DATE_FORMATS,
  APP_DATE_TIME_FORMATS,
  USE_UTC,
} from './lib/form-controls';
import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import {
  NgxMatDateAdapter,
  NGX_MAT_DATE_FORMATS,
} from '@angular-material-components/datetime-picker';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { TenantDateTimeAdapter } from './lib/form-controls/form-input-date-time/tenant-date-time-adaptor';
import { CustomMomentDateAdapter } from './lib/form-controls/form-input-date/custom-moment-date-adapter';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { AgGridModule } from 'ag-grid-angular';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire/compat';
import { TuulaCommonComponentModule } from 'tuula-common';
import { StandaloneComponents } from './lib/standalone-components';

const importExport = [
  FormControlsModule,
  GeneralComponentsModule,
  DirectivesModule,
  PipesModule,
  SharedFormsModule,
  DynamicFormsModule,
  FullCalendarModule,
  NgxJsonViewerModule,
  AgGridModule,
  TuulaCommonComponentModule,
  StandaloneComponents
];

@NgModule({
  imports: [
    ...importExport,
    MaterialModule,
    AngularFireMessagingModule,
    AngularFireModule,
  ],
  exports: [...importExport],
})
export class C4pUtilModule {
  public static forRoot(environment: any): ModuleWithProviders<C4pUtilModule> {
    return {
      ngModule: C4pUtilModule,
      providers: [
        {
          provide: 'environment',
          useValue: environment,
        },
        {
          provide: FIREBASE_OPTIONS,
          useValue: environment.firebase,
        },
        // date
        {
          provide: DateAdapter,
          useClass: CustomMomentDateAdapter,
          deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
        {
          provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
          useValue: { useUtc: USE_UTC },
        },
        // datetime
        {
          provide: NgxMatDateAdapter,
          useClass: TenantDateTimeAdapter,
          deps: [MAT_DATE_LOCALE, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: NGX_MAT_DATE_FORMATS, useValue: APP_DATE_TIME_FORMATS },
        {
          provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS,
          useValue: { useUtc: USE_UTC },
        },
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideMessaging(() => getMessaging()),
      ],
    };
  }
}
