import {
  Component,
  Host,
  Input,
  Optional,
  SkipSelf,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { ValidationMessageComponent } from '../validation-message/validation-message.component';

@Component({
  selector: 'app-nd-form-input-checkbox',
  templateUrl: './nd-form-input-checkbox.component.html',
  styleUrls: ['./nd-form-input-checkbox.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ValidationMessageComponent,
    MatCheckboxModule,
    MatFormFieldModule,
  ],
  providers: [],
})
export class FormInputCheckBoxNdComponent {
  @Input({
    required: true,
    transform: (value: string) => {
      return 'checkbox-input-' + value;
    },
  })
  testId: string | undefined;
  @Input() label: string = '';
  @Input() hint: string | undefined;
  @Input() alignHint: "start" | "end" = "start";
  @Input() title:string = ""
  @Input() size: 'md-size' | 'sm-size' = 'sm-size';

  @Input() disabled: boolean = false;

  @Input() labelPosition: 'before' | 'after' = 'after';

  @Input() controlName: string | undefined;
  @Input() control: AbstractControl | null;

  readonly requiredTrue = Validators.requiredTrue;

  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
  ) {}

  ngOnInit() {
    if (!this.control && this.controlName) {
      this.control = (this.controlContainer?.control as FormGroup)?.get(
        this.controlName,
      );
    }
    if (this.control && this.disabled) {
      this.control.disable();
    }
  }
}
