import {
  Component,
  Optional,
  Host,
  SkipSelf,
  ViewEncapsulation,
  signal,
  OnInit,
  input
} from '@angular/core';
import {
  ControlContainer,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import {
  DEFAULT_MIN_DATE_ND,
  DEFAULT_MAX_DATE_ND,
  DATE_REGEX,
  DEFAULT_START_DATE_PLACEHOLDER,
  DEFAULT_END_DATE_PLACEHOLDER,
  APP_DATE_FORMATS_ND,
} from '../../constants/form-controls.const';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ValidationMessageComponent } from '../validation-message/validation-message.component';
import { DateUtils } from '../../utilities';
@Component({
  selector: 'app-nd-form-input-date-range',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    TranslateModule,
    MatDatepickerModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    ValidationMessageComponent,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './nd-form-input-date-range.component.html',
  styleUrls: ['./nd-form-input-date-range.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS_ND }],
})
export class FormInputDateRangeNdComponent implements OnInit{
  readonly label = input<string>('');
  readonly disabled = input<boolean>(false);
  readonly startDatePlaceholder = input(DEFAULT_START_DATE_PLACEHOLDER);
  readonly endDatePlaceholder = input(DEFAULT_END_DATE_PLACEHOLDER);
  readonly minDate = input(DEFAULT_MIN_DATE_ND);
  readonly maxDate = input(DEFAULT_MAX_DATE_ND);
  readonly hint = input<string>();
  readonly testId = input.required<string, string>({ transform: (value: string) => {
        return 'app-form-input-date-range' + value;
    } });
  checkDateRegex = DATE_REGEX;
  startDate: Date;
  endDate: Date;
  required = signal<boolean>(false);

  constructor(
    @Optional() @Host() @SkipSelf() public controlContainer: ControlContainer,
  ) {}

  ngOnInit(): void {
    if (this.controlContainer.control) {
      this.setDefaultValues();
    } 
  }

  /**
   * Below dateChange methods handle the change event for the input of the start/end date manual.
   * Validates the start/end date and sets appropriate errors if necessary.
   * @param event$ - The event object from the start/end date input change.
   */
  onStartDateChange(event$: Event) {
    const startDateValue = (event$.target as HTMLInputElement)?.value;

    if (!startDateValue) {
      this.fixRequiredError(event$);
      return;
    }

    if (!DateUtils.isValidDate(startDateValue)) {
      this.controlContainer?.control
        ?.get('startDate')
        ?.setErrors({ matDatepickerParse: true });
      return;
    }

    this.startDate = DateUtils.parseStringToDate(startDateValue);

    if (
      this.startDate &&
      (this.startDate > this.maxDate() || this.startDate < this.minDate())
    ) {
      this.controlContainer?.control
        ?.get('startDate')
        ?.setErrors({ matDatepickerParse: true });
      return;
    }

    if (this.controlContainer?.control?.get('startDate')?.errors) {
      this.controlContainer?.control?.get('startDate')?.setErrors(null);
    }

    if (this.startDate && this.endDate && this.startDate > this.endDate) {
      this.controlContainer?.control
        ?.get('startDate')
        ?.setErrors({ matDatepickerMin: true });
      return;
    } else {
      if (this.controlContainer?.control?.get('startDate')?.errors) {
        delete this.controlContainer?.control?.get('startDate')?.errors?.[
          'matDatepickerMin'
        ];
      }
      if (this.controlContainer?.control?.get('endDate')?.errors) {
        delete this.controlContainer.control.get('endDate')?.errors?.[
          'matDatepickerMin'
        ];
      }
    }

    if (!this.endDate && this.required()) {
      this.controlContainer.control
        .get('endDate')
        .setErrors({ required: true });
    } else if (!this.controlContainer?.control?.get('endDate')?.value && this.required()) {
      this.controlContainer.control
        .get('endDate')
        .setErrors({ matDatepickerParse: true });
    } else {
      this.controlContainer.control.get('endDate').setErrors(null);
    }

  }

  

  onDateRangeChange() {
    const startDateValue = this.controlContainer.control?.get('startDate')?.value;
    this.startDate = DateUtils.convertMomentToDate(startDateValue);
    const endDateValue = this.controlContainer.control?.get('endDate')?.value;
    this.endDate = DateUtils.convertMomentToDate(endDateValue);
  }

  onEndDateChange(event$: Event) {
    const endDateValue = (event$.target as HTMLInputElement)?.value;
    if (!endDateValue) {
      this.fixRequiredError(event$);
      return;
    }
    if (!DateUtils.isValidDate(endDateValue)) {
      this.controlContainer?.control
        ?.get('endDate')
        ?.setErrors({ matDatepickerParse: true });
      return;
    }
    this.endDate = DateUtils.parseStringToDate(endDateValue);
    if (
      this.endDate &&
      (this.endDate > this.maxDate() || this.endDate < this.minDate())
    ) {
      this.controlContainer?.control
        ?.get('endDate')
        ?.setErrors({ matDatepickerParse: true });
      return;
    }

    if (this.controlContainer?.control?.get('endDate')?.errors) {
      this.controlContainer.control.get('endDate')?.setErrors(null);
    }
    
    if (this.startDate && this.endDate && this.startDate > this.endDate) {
      this.controlContainer?.control
        ?.get('endDate')
        ?.setErrors({ matDatepickerMin: true });
      return;
    } else {
      if (this.controlContainer?.control?.get('startDate')?.errors) {
        delete this.controlContainer.control.get('startDate')?.errors?.[
          'matDatepickerMin'
        ];
      }
      if (this.controlContainer?.control?.get('endDate')?.errors) {
        delete this.controlContainer.control.get('endDate')?.errors?.[
          'matDatepickerMin'
        ];
      }
    }
    if (!this.startDate && this.required()) {
      this.controlContainer.control
        .get('startDate')
        .setErrors({ required: true });
    } else if (!this.controlContainer?.control?.get('startDate')?.value && this.required()) {
      this.controlContainer.control
        .get('startDate')
        .setErrors({ matDatepickerParse: true });
    } else {
      this.controlContainer.control.get('startDate').setErrors(null);
    }
  }

  setDefaultValues() {
    this.required.set(
      this.controlContainer.control
        .get('startDate')
        .hasValidator(Validators.required),
    );
  }
  fixRequiredError(event$: Event) {
    const date = (event$?.target as HTMLInputElement).value;
    if (date) {
      const startErrors =
        this.controlContainer.control?.get('startDate')?.errors;
      delete startErrors?.['required'];
      this.controlContainer.control?.get('startDate')?.setErrors(startErrors);
      const endErrors = this.controlContainer?.control?.get('endDate').errors;
      delete endErrors?.['required'];
      this.controlContainer.control?.get('endDate')?.setErrors(startErrors);
    } else {
      this.controlContainer.control
        .get('startDate')
        .setErrors({ required: true });
    }
  }

}

