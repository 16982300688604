import { Component, OnInit, ViewEncapsulation, input } from '@angular/core';
import { TableColumn } from '../../../models';
import { MatTableModule } from '@angular/material/table';
import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-tuula-table-skeleton',
  templateUrl: './tuula-table-skeleton.component.html',
  styleUrl: './tuula-table-skeleton.component.scss',
  encapsulation: ViewEncapsulation.None,
  standalone:true,
  imports:[MatTableModule,NgStyle,TranslateModule,NgClass,NgTemplateOutlet]
})
export class TuulaTableSkeletonComponent implements OnInit{

  readonly columns = input<TableColumn[]>(undefined);
  readonly totalRowWidth = input(undefined);
  readonly defaultCellWidth = input(undefined);
  readonly tableRowSize = input(undefined);
  readonly rowCount = input(5);

  tableDispayColumns = [];
  skeletonRows;

  constructor() { }

  ngOnInit(): void {
    this.tableDispayColumns = this.columns().filter((column) => !column.hidden).map(col => col.prop);
    const count = this.rowCount() > 10 ? 10 : this.rowCount();
    this.skeletonRows = Array(count).fill({});
  }


}
