
<div class="text-area-wrapper">
  <label>
    <mat-label>{{label() | translate}}@if(required()){<span >*</span>}</mat-label>
  </label>

  <mat-form-field class="textarea-input-field" appearance="fill" [attr.disableResize]="disableResize() ? '' :null" >
    <textarea
      [attr.testId]="testId()"
      type="{{ type() }}"
      matInput
      [formControl]="$any(control)"
      placeholder="{{placeholder()|translate}}"
      [required]="required()"
      tabindex="0"
      contenteditable="true"
      aria-multiline="true"
      [rows]="rows()"
      [maxLength]="limit()"
      aria-required="true"
    ></textarea>
    <mat-hint></mat-hint>
    <mat-error class="custom-input-error">
      @if(control?.errors){
      <app-validation-message
      [errors]="control.errors"
      ></app-validation-message>
      }
    </mat-error>
  </mat-form-field>
</div>