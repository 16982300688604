import { NgModule } from "@angular/core";
import { FormControlsNdModule } from "./nd-form-controls"
import { ComponentsModule } from "./components" 
import { PipesModule } from "tuula-common";
import { DirectivesModule } from "./directives";



@NgModule({
  imports: [FormControlsNdModule,ComponentsModule,PipesModule,DirectivesModule],
  bootstrap: [],
  declarations: [],
  exports: [FormControlsNdModule,ComponentsModule,PipesModule,DirectivesModule],
  providers: [],
  schemas: [],
})
export class TuulaCommonComponentModule {}