import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls';
import { ModelUtils } from '../../../utilities';
import { MasterDataService } from '../../master-data/master-data.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LawService {
  public values: IFormDropdownOption[] = [];
  public lookupValues: string[] = [];

  public filteredValues: IFormDropdownOption[];

  constructor(private masterDataService: MasterDataService) {
    this.getLaws();
  }

  public filterLaws(search: string) {
    this.filteredValues = ModelUtils.searchInArray(
      this.values,
      search,
      'value',
    );
  }

  public getLaws() {
    this.masterDataService.getMasterData('laws').subscribe((data) => {
      this.values = data;
      for (let item of this.values) {
        this.lookupValues[item.value] = item.label;
      }
      this.filteredValues = data;
    });
  }

  public fetchLaws(): Observable<any[]> {
      return this.masterDataService.getMasterData('laws').pipe(map((data:any) => {
        return data.map((item) => {
          return {label: item.label, value: item.value, id: item.value }
        })
      })
    );
  }

}
