@if(breadCrumbs().length > 1){
  <span 
  class="breadcrumb-back-icon-wrapper md-none"
  (click)="backToPreviousPage()"
    appClickable
  >
    <i 
    class="ai-icon-arrow-narrow-left ai-icon-1-5x"
    ></i>
  </span>
}
<span class="breadcrumb-title-container">
  @if(loading()){
    <span class="breadcrumb-title-loading" >
       {{'general.status.Loading' | translate}}...
    </span>
  }@else{
    @for (breadcrumb of breadCrumbs(); track $index; let i = $index; let last = $last){
        @if(breadcrumb?.label){
          <span
            class="tuula-breadcrumb"
            [routerLink]="breadcrumb.url"
            [ngClass]="{ 
              'tuula-breadcrumb-active': last,
              'md-none': i !==0
             }"
          >
            {{ breadcrumb.label | translate }}
            @if (!last) {
              <span class="md-none" >|</span>
            }
          </span>
        }
    }
  }
</span>