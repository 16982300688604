import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControlBase } from '../../../form-controls';

@Component({
  selector: 'app-scale-choice',
  templateUrl: './scale-choice.component.html',
  styleUrls: ['./scale-choice.component.scss'],
})
export class ScaleChoiceComponent extends FormControlBase<ScaleChoiceComponent> {
  @Input() pattern: string;
  @Input() hint: string;
  @Input() isClientPortal = false;
  @Input() orderNum: number;
  @Input() minLimit: number;
  @Input() maxLimit: number;
  @Output() tabEvent = new EventEmitter();

  ngDoCheck(): void {
    this.adjustTouched();
  }

  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return `${value}`;
  }

  onScaleChange(event: any) {
    // mandatory scale types can't have 0 as valid value
    if (event.value === 0) {
      this.valueControl.setValue('', { emitEvent: true });
    } else {
      this.valueControl.setValue(event.value, { emitEvent: true });
    }
  }

  onTab(el: HTMLElement) {
    this.tabEvent.emit(el.offsetHeight);
  }
}
