import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  Host,
  inject,
  Input,
  Optional,
  signal,
  SkipSelf,
  input
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  ControlContainer,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { tap } from 'rxjs';
import {
  DEFAULT_INPUT_TYPE,
  DEFAULT_TEXT_AREA_COLUMNS,
  DEFAULT_TEXT_AREA_DISABLE_RESIZE,
  DEFAULT_TEXT_AREA_ROWS,
} from '../../constants/form-controls.const';
import { ValidationMessageComponent } from '../validation-message/validation-message.component';

@Component({
  selector: 'app-nd-form-input-text-area',
  templateUrl: './nd-form-input-text-area.component.html',
  styleUrls: ['./nd-form-input-text-area.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    ValidationMessageComponent,
    CommonModule,
    TranslateModule,
  ],
  providers: [],
})
export class FormInputTextAreaNdComponent {
  readonly testId = input.required<string, string>({ transform: (value: string) => {
        return 'text-area-input-' + value;
    } });
  readonly label = input<string>('');
  readonly placeholder = input<string>('');
  readonly disabled = input<boolean>(false);

  readonly type = input(DEFAULT_INPUT_TYPE);

  readonly transformFns = input<((value: string) => string)[]>([]);

  //-TEXT-AREA
  readonly cols = input(DEFAULT_TEXT_AREA_COLUMNS);
  readonly rows = input(DEFAULT_TEXT_AREA_ROWS);
  readonly disableResize = input(DEFAULT_TEXT_AREA_DISABLE_RESIZE);
  readonly autoResize = input(false); //DEFAULT_AUTO_RESIZE;
  readonly limit = input('500');

  readonly controlName = input<string>(undefined);
  @Input() control: AbstractControl | null;

  required = signal<boolean>(false);

  readonly #destroyRef = inject(DestroyRef);


  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
  ) {}

  ngOnInit() {
    const controlName = this.controlName();
    if (!this.control && controlName) {
      this.control = (this.controlContainer?.control as FormGroup)?.get(
        controlName,
      );
    }

    if (this.control) {
      this.setDefaultValues();

      if (this.disabled()) {
        this.control.disable();
      }

      this.control.valueChanges
        .pipe(
          tap((value: string) => {
            let transformedValue = value;
            this.transformFns().forEach((fn) => {
              transformedValue = fn(transformedValue);
            });
            if (value !== transformedValue) {
              this.control?.setValue(transformedValue, { emitEvent: false });
            }
          }),
          takeUntilDestroyed(this.#destroyRef),
        )
        .subscribe();
    }
  }

  setDefaultValues() {
    this.required.set(this.control?.hasValidator(Validators.required)!);
  }
}
