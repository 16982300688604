import { NgTemplateOutlet } from '@angular/common';
import {
  Component,
  computed,
  DestroyRef,
  EventEmitter,
  Host,
  inject,
  Input,
  model,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  signal,
  SimpleChanges,
  SkipSelf,
  ViewEncapsulation,
  input
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  ControlContainer,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { debounce, distinctUntilChanged, timer } from 'rxjs';
import {
  DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT,
} from '../../constants/form-controls.const';
import { ValidationMessageComponent } from '../validation-message/validation-message.component';
import { FunctionCallPipe } from '../../pipes';

@Component({
  selector: 'app-nd-form-input-dropdown',
  templateUrl: './nd-form-input-dropdown.component.html',
  styleUrls: ['./nd-form-input-dropdown.component.scss'],
  standalone: true,
  imports: [
    MatSelectModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    TranslateModule,
    ValidationMessageComponent,
    MatFormFieldModule,
    FormsModule,
    FunctionCallPipe
  ],
  encapsulation: ViewEncapsulation.None,
})
export class FormInputDropdownNdComponent
  implements OnInit, OnChanges, OnDestroy
{
  readonly testId = input.required<string, string>({ transform: (value: string) => {
        return 'dropdown-input-' + value;
    } });
  readonly label = input<string>('');

  readonly controlName = input<string>(undefined);
  @Input() control: AbstractControl | null;

  readonly disabled = input<boolean>(false);
  readonly frontendFiltering = input(false);
  readonly frontendFilteringProperty = input('value');

  readonly objectAsValue = input(false);
  readonly labelProperty = input<string>('label');
  readonly optionDisplayFunction = input<any>((option: {
    [x: string]: any;
  }) => {
    return option?.[this.labelProperty()];
  });
  readonly maxContentSelectionPanel = input(false);
  
  readonly hint = input<string>(undefined);
  readonly alignHint = input<'start' | 'end'>('start');
  
  @Input() valueProperty: string = 'id';

  //- OPTIONS

  options = model<any[]>([]);
  missingValues = signal<any[]>([]);


  @Input() set listOptions(list: any[]) {
    const mappedList = list.map((value) => {
      return { value, label: value };
    });
    this.options.set(mappedList);
    this.selectSingleOrAll();
  }
  filteredOptions = computed(() => {
    const options = this.getFilteredOptions();
    const formattedOptions = this.getFormattedOptions(options);
    this.isSearchResultLoading = false;
    return formattedOptions;
  });

  readonly multiSelection = input(false);
  readonly selectAll = input(false);
  readonly selectSingle = input(false);

  //-SEARCH
  readonly search = input<boolean>(false);
  searchControl = new FormControl();
  isSearchResultLoading: boolean = false;
  searchSignal = signal<string>('');
  @Output() filterChanged = new EventEmitter<string>();

  readonly #destroyRef = inject(DestroyRef);
  isIndeterminate = signal(false);
  isChecked = signal(false);
  readonly placeholder = input<string>();

  currentOption:any[]=[];
  required = signal<boolean>(false);

  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
  ) {}

  ngOnInit() {
    const controlName = this.controlName();
    if (!this.control && controlName) {
      this.control = (this.controlContainer?.control as FormGroup)?.get(
        controlName,
      );
    }else if(!this.control){
      this.control= new FormControl();
    }

    if (this.control) {
      this.setDefaultValues();
      
      if (this.disabled()) {
        this.control.disable();
      }
    }
    if(this.search()) this.filterChanged.emit('')
    this.searchControl.valueChanges
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        debounce(() =>
          this.frontendFiltering()
            ? timer(0)
            : timer(+DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT),
        ),
      )
      .subscribe((value) => {
        this.isSearchResultLoading = true;
        if (this.search() && !this.frontendFiltering())
          this.filterChanged.emit(value);
        else this.searchSignal.set(value);
      });

    if (this.control?.value) this.assignMissingValues();
    this.control?.valueChanges.pipe(
      takeUntilDestroyed(this.#destroyRef),
      distinctUntilChanged()
    ).subscribe(data=>{
      if(data && this.search() && !this.multiSelection()){
        this.currentOption=this.filteredOptions().filter((option: { [x: string]: any; })=>{
          if(this.objectAsValue()){
            return option?.[this.valueProperty]===this.control?.value?.[this.valueProperty]
          }else{
            return option?.[this.valueProperty]===this.control?.value
          }
        })
      }else if(!data){
        this.currentOption=[]
      }
    })


    if (this.multiSelection() && this.control?.value && !this.objectAsValue()) {
      this.control.setValue(this.control?.value?.map((item: { [x: string]: any; })=>item?.[this.valueProperty]));
    }
    this.selectSingleOrAll();
  }


  private selectSingleOrAll() {
    if(this.disabled() || this.control?.value) return;
    if (this.control &&
      this.selectSingle() &&
      this.options()?.length === 1) {
      const value= this.objectAsValue() ? this.filteredOptions()?.[0] : this.filteredOptions()?.[0]?.[this.valueProperty]
      this.control.setValue(value);
    } else if (this.control &&
      this.selectAll() &&
      this.multiSelection() &&
      this.options().length > 0) {
      const value = this.objectAsValue()
        ? this.filteredOptions()
        : this.filteredOptions().map((option: { [x: string]: any; }) => option?.[this.valueProperty]);

      if(this.search())this.isChecked.set(true);
      this.control.patchValue(value);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.['options']) {
      if(this.search() && !this.multiSelection()){
        this.options.update((data)=>[...mergeArrays(this.currentOption, data, this.valueProperty)])
      }
      this.isSearchResultLoading = false;
      this.selectSingleOrAll();
    }
  }

  ngOnDestroy() {

  }

  assignMissingValues() {

      if (Array.isArray(this.control?.value)) {
        this.missingValues.set([...(this.control?.value )]);
      } else {
        this.missingValues.set([this.control?.value]);
      }

    if(!this.objectAsValue() && !this.multiSelection()){
      const value =this.control?.value
      if(typeof value === 'object' && !Array.isArray(value) && value !== null)this.control?.patchValue(value?.[this.valueProperty],{emitEvent:false})
    }
  }



  putValue=({option,objectAsValue}:{option:{ [x: string]: any; },objectAsValue:boolean})=> {
    return objectAsValue ? option : option?.[this.valueProperty];
  }

  getFilteredOptions() {
    const mergedArrays = mergeArrays(this.missingValues(), this.options(), this.valueProperty);
    this.fixValueProperty(mergedArrays);
    const options = [...new Set(mergedArrays)];
    if (this.frontendFiltering()) {
      return options.filter((option) => {
        const filterValue = this.searchSignal();
        return option?.[this.frontendFilteringProperty()]?.includes(filterValue);
      });
    }

    return options;
  }

  private fixValueProperty(mergedArrays: any) {
    if(mergedArrays.length===0) return
    const idValuePropertyFound = mergedArrays?.find((option: { [x: string]: any; }) => {
      if (this.valueProperty === "id") return !!option?.[this.valueProperty];
    });
    if (!idValuePropertyFound) this.valueProperty = "value";
  }

  getFormattedOptions(options: any[]) {
    if (this.objectAsValue()) {
      return options;
     }

    return options.map((option) => ({
      value: option?.[this.valueProperty],
      label: this.optionDisplayFunction()(option),
      ...option
    })).filter(option=>!!option?.value || option?.value === 0);
  }


  toggleSelectAll(e: boolean) {
    this.isChecked.set(e);
    const value = this.objectAsValue()
      ? this.filteredOptions()
      : this.filteredOptions().map((option) => option?.[this.valueProperty]);
    if (e) {
      this.control?.setValue(value);
    } else {
      this.control?.setValue([]);
    }
  }

  setDefaultValues() {
    this.required.set(this.control?.hasValidator(Validators.required)!);
  }

}




function mergeArrays(firstArray: any[], secondArray:any[], uniqueKey: string) {
  const combinedArray = [...firstArray];
  let firstArrayMap = firstArray?.reduce((acc, obj) => {
    acc[obj[uniqueKey]] = obj;
    return acc;
  }, {});

  secondArray?.forEach(item => {
    if (firstArrayMap[item[uniqueKey]]) {
      firstArrayMap[item[uniqueKey]] = {
        ...firstArrayMap[item[uniqueKey]],
        ...item
      };
    } else {
      combinedArray.push(item);
    }
  });
  return combinedArray;
}
