import { Component, computed, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { BreadcrumbStService } from '../services';
import { TranslateModule } from '@ngx-translate/core';
import { ClickableDirective } from 'tuula-common';



@Component({
  selector: 'app-breadcrumb-st',
  standalone: true,
  imports: [CommonModule, RouterLink,TranslateModule,ClickableDirective],
  templateUrl: './breadcrumb-st.component.html' ,
  styleUrls: ['./breadcrumb-st.component.scss'],
})
export class BreadcrumbStComponent {
  private readonly breadCrumbStService=inject(BreadcrumbStService);
  private readonly router = inject(Router);

  loading = computed(() => this.breadCrumbStService.breadcrumbs().find(item=>item?.loading));
  breadCrumbs=computed(()=>{
    return this.breadCrumbStService.breadcrumbs().filter(item=>item?.label && item?.url)
  })

  backToPreviousPage() {
    const breadcrumbs = this.breadCrumbs();
    if (breadcrumbs && breadcrumbs.length > 1) {
      const previousBreadcrumb = breadcrumbs?.[0];
      if (previousBreadcrumb?.url) {
        this.router.navigate([previousBreadcrumb.url]);
      }
    }
  }
}