<ng-container [formGroup]="filtersForm">
  <app-panel-form-row>
    <ng-container *ngIf="!carefileView">
      <app-form-input-text
        formControlName="client.clientNumber"
        placeholder="careprogram.labels.ClientId"
      ></app-form-input-text>
      <app-form-input-text
        formControlName="bsn"
        placeholder="careprogram.labels.BSN"
        [errorLabels]="{ bsnValidator: 'boilerplate.validation.BsnValidator' }"
      ></app-form-input-text>
      <app-form-input-text
        formControlName="client.fullName"
        placeholder="careprogram.labels.ClientFullName"
      ></app-form-input-text>
      <app-form-input-date
        formControlName="client.dateOfBirth"
        placeholder="careprogram.labels.ClientDateOfBirth"
        [maxDate]="getMaxDate()"
      ></app-form-input-date>
    </ng-container>
    <!-- TODO: Filter object for therapists -->
    <app-form-input-dropdown
      formControlName="therapist.id"
      placeholder="careprogram.labels.MainTherapist"
      [options]="$any(employeeService.values)"
      [dropdownService]="employeeService"
      [filterObject]="mainTherapistFilterObject"
      [search]="true"
      labelProperty="name"
    ></app-form-input-dropdown>
    <app-form-input-date
      formControlName="registrationDate"
      placeholder="careprogram.labels.RegistrationDate"
    ></app-form-input-date>
  </app-panel-form-row>

  <app-panel-form-row>
    <button class="c4p-button" (click)="filtersForm.reset()" translate>
      general.actions.Reset
    </button>
  </app-panel-form-row>
</ng-container>
