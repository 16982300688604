import {
  Component,
  ElementRef,
  Host,
  Input,
  Optional,
  SkipSelf,
  TemplateRef,
  input
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import {
  DEFAULT_RADIO_DIRECTION,
  Option,
} from '../../constants/form-controls.const';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { ValidationMessageComponent } from '../validation-message/validation-message.component';

@Component({
  selector: 'app-nd-form-input-radio',
  templateUrl: './nd-form-input-radio.component.html',
  styleUrls: ['./nd-form-input-radio.component.scss'],
  standalone: true,
  imports: [
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    TranslateModule,
    ValidationMessageComponent,
  ],
})
export class FormInputRadioNdComponent {
  readonly testId = input.required<string, string>({ transform: (value: string) => {
        return 'radio-input-' + value;
    } });

  readonly options = input<Option[]>([]);

  readonly disabled = input<boolean>(false);

  readonly controlName = input<string>(undefined); // Name of the form control
  @Input() control: AbstractControl | null;

  @Input() groupHint: string = '';

  readonly direction = input<'row' | 'column'>(DEFAULT_RADIO_DIRECTION);
  readonly size = input<'md-size' | 'sm-size'>('sm-size');
  readonly label = input<string>(undefined);
  @Input() radioLabelTemplate: TemplateRef<any>;
  readonly radioStyleWrapperContainer = input<string>('');
  readonly radioGroupContainer = input<string>('');
  readonly radioButtonStyle = input<string>('');
  readonly labelStyle = input<string>('');

  public required = Validators.required;

  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
    private el: ElementRef,
  ) {}

  ngOnInit() {
    const controlName = this.controlName();
    if (!this.control && controlName) {
      this.control = (this.controlContainer?.control as FormGroup)?.get(
        controlName,
      );
    }
    if (this.control && this.disabled()) {
      this.control.disable();
    }
  }
}
