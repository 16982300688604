
  <mat-form-field class="custom-month-year-input-form">
    <mat-label class="custom-month-year-label"> {{ label() | translate }}</mat-label>
    <div class="custom-month-year-input" [attr.disabled]="disabled() ? '' : null">
      <input
        matInput
        [formControl]="$any(control)"
        [matDatepicker]="datePicker"
        [min]="minDateValue"
        [max]="maxDateValue"
        maxlength="10"
        [required]="required()"
        (keydown)="dateMonthYearRegex($event)"
        (dateChange)="onChangeDate()"
        (change)="valueChangeHandler($event)"
        [attr.testId]="testId()"
        (dblclick)="setNow()"
      />

      <mat-datepicker-toggle
      class="custom-month-year-toggle-suffix"
       [for]="datePicker"
     ></mat-datepicker-toggle>

    </div>

    <mat-hint class="custom-month-year-input-hint">{{ hint() ?? '' }}</mat-hint>

    <mat-error class="custom-month-year-input-error">
      @if(control?.errors){
      <app-validation-message [errors]="control.errors"></app-validation-message>
      }
    </mat-error> 

  </mat-form-field>


<mat-datepicker
panelClass="custom-mat-month-year-panel-class"
#datePicker
(opened)="onDatepickerOpened()"
(closed)="onDatepickerClosed()"
startView="multi-year"
(monthSelected)="setMonthAndYear($event, datePicker)"
></mat-datepicker>