<div class="c4p-form-input">
  <mat-form-field>
    <mat-label>{{ placeholder | translate }}</mat-label>
    <mat-date-range-input
      [required]="required"
      [formGroup]="$any(controlContainer.control)"
      [rangePicker]="picker"
    >
      <input
        matStartDate
        formControlName="startDate"
        placeholder="{{ startDatePlaceholder | translate }}"
        (keydown)="onKeydown($event)"
        (dblclick)="setNow('startDate')"
        maxlength="10 "
      />
      <input
        matEndDate
        formControlName="endDate"
        placeholder="{{ endDatePlaceholder | translate }}"
        (keydown)="onKeydown($event)"
        (dblclick)="setNow('endDate')"
        maxlength="10 "
      />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>

    <mat-error>Invalid date span</mat-error>
  </mat-form-field>
</div>
