import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  Optional,
  Self,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormControlBase } from '../abstract-form-control';
import {
  DEFAULT_MIN_DATE,
  DEFAULT_MAX_DATE,
  DATE_REGEX,
  DEFAULT_DATEPICKER_ERRORS_ARRAY,
} from '../form-controls.const';
import * as moment_ from 'moment';
import { DateInputErrorStateMatcher } from '../form-control.error-matcher';
import { NgControl } from '@angular/forms';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
const moment = moment_;

import { MatDatepicker } from '@angular/material/datepicker';
import { DateUtils } from '../../utilities';
import { CustomMomentDateAdapter } from '../form-input-date/custom-moment-date-adapter';

const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-form-input-month-year',
  templateUrl: './form-input-month-year.component.html',
  styleUrls: ['./form-input-month-year.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class FormInputMonthYearComponent extends FormControlBase<FormInputMonthYearComponent> {
  static DEFAULT_DATEPICKER_ERRORS_ARRAY = DEFAULT_DATEPICKER_ERRORS_ARRAY;
  get DEFAULT_DATEPICKER_ERRORS() {
    return FormInputMonthYearComponent.DEFAULT_DATEPICKER_ERRORS_ARRAY;
  }
  @Input() minDate = DEFAULT_MIN_DATE;
  @Input() maxDate = DEFAULT_MAX_DATE;
  @Input() startView: "month" | "year" | "multi-year" = 'month';
  // dateFilter accepts a function with date parameter and should return a boolean value to determine whether to disable or enable dates
  // it can be used in conjunction with minDate and maxDate
  @Input() dateFilter: any;

  @Output() openedDateStream: EventEmitter<void> = new EventEmitter<void>();
  @Output() closedDateStream: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('datePickerInput') datePickerInput: ElementRef;
  onKeydown = DATE_REGEX;

  private _innerValue;
  get value() {
    return this._innerValue;
  }
  set value(value: any) {
    if (value !== this.value) {
      this._innerValue = value;
      this.onChangeCallback && this.onChangeCallback(value);
    }
    this.stateChanges.next();
  }

  constructor(@Optional() @Self() public ngControl: NgControl) {
    super(ngControl);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
      this.errorStateMatcher = new DateInputErrorStateMatcher(this.ngControl);
    }
  }

  writeValue(value) {
    if (this.datePickerInput?.nativeElement && value === null) {
      // remove leftover strings when reseting the form
      this.datePickerInput.nativeElement.value = '';
      // set null value to remove default datepicker errors
      this.valueControl.setValue(null);
    }
    super.writeValue(value);
  }

  public setNow() {
    // const now = USE_UTC ? moment.utc() : moment();
    // // remove leftover strings when reseting the form
    // this.datePickerInput.nativeElement.value = now.local().format('DD/MM/YYYY HH:mm');
    // // set null value to remove default datepicker errors
    // this.value = USE_UTC ? now.utc() : now;

    this.value = DateUtils.getCurrentTenantDayMoment();
  }

  onOpenedDateStream(): void {
    this.openedDateStream.emit();
  }

  onClosedDateStream(): void {
    this.closedDateStream.emit();
  }

  chosenMonthHandler(
    normalizedMonth: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>,
  ) {
    if (this.startView == 'multi-year') {
      datepicker.select(normalizedMonth);
      datepicker.close();
    }
  }
}
