import {
    Component,
    OnInit,
    inject,
    Output, EventEmitter, input, signal,
  } from '@angular/core';
  import { ControlContainer, FormsModule, ReactiveFormsModule } from '@angular/forms';
  import { CommonModule } from '@angular/common';
  import { TranslateModule } from '@ngx-translate/core';
  import {QuillEditorComponent, QuillViewHTMLComponent, SelectionChange} from 'ngx-quill';
  import Quill from 'quill';
  @Component({
    selector: 'app-rich-text',
    templateUrl: './rich-text.component.html',
    styleUrls: ['./rich-text.component.scss'],
    standalone: true,
    imports: [
      FormsModule,
      CommonModule,
      TranslateModule,
      QuillEditorComponent,
      ReactiveFormsModule,
      QuillViewHTMLComponent,
    ],
  })
  export class RichTextComponent implements OnInit {
    controlName = input<string | undefined>(undefined);
    placeholder=input('');
    content = input();
    class = input();
    readOnly = input();
    isToolbar = input(true);
    style = input();
    isHtml = input(false);
    control = signal(null);
    @Output() onBlur: EventEmitter<any> = new EventEmitter<any>();
    @Output() onEditorCreated: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSelectionChanged: EventEmitter<any> = new EventEmitter<any>();
    quillModules = {
      syntax: false,
      toolbar: [
        [{ header: [1, 2, 3, false] }], // Header options
        ['bold', 'italic', 'underline'], // Text formatting
        [{ list: 'ordered' }, { list: 'bullet' }], // Lists
        [{ align: [] }], // Alignment
        [{ color: [] }], // Text color and background color
        ['clean'], // Clear formatting
      ],
    };
    private controlContainer = inject(ControlContainer, { optional: true });
    constructor() {}
    ngOnInit(): void {
      if (!this.control() && this.controlName() && this.controlContainer?.control) {
        this.control.set(this.controlContainer.control.get(this.controlName()));
      }
    }
    blur($event: any): void {
      this.onBlur.emit($event)
    }
    created($event: Quill) {
      this.onEditorCreated.emit($event)
    }
    selectionChanged($event: SelectionChange) {
      this.onSelectionChanged.emit($event)
    }
  }