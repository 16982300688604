import { Component, EventEmitter, Output, TemplateRef, ViewChild, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-tuula-table-action-button',
  templateUrl: './tuula-table-action-button.component.html',
  styleUrl: './tuula-table-action-button.component.scss',
  standalone:true,
  imports:[MatIconModule,TranslateModule,MatTooltipModule]
})
export class TuulaTableActionButtonComponent {

  readonly name = input<string>('');
  readonly icon = input<string>('');
  readonly tooltip = input<string>('');
  readonly disabled = input<boolean>(false);
  readonly row = input<any>(undefined);
  @Output() click = new EventEmitter();
  @ViewChild('actionTemplate', {static : true}) template: TemplateRef<any>;
  overflow: boolean = false;

  constructor() { }

  handleClick() {
    this.click.emit(this.row());
  }

}
