import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css'],
})
export class AccordionComponent implements OnInit, OnChanges {
  @Input() panelOpenState = false;
  @Input() title: string | string[] | boolean[];
  @Input() hideToggle: boolean = false;
  @Input() doCollapse = true;
  @Input() hideShadow = false;
  @Input() multi = true;
  @Input() selected = false;
  @Input() decoratedHeader = false;
  @Input() expandOnIcon = false;
  @Input() isEditable = false;
  @Input() autoCollapseText: string;

  @Input() stepIndex = 0;
  @Output() opened = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Output() changeValue = new EventEmitter();

  @ViewChild(MatExpansionPanel) matExpansionPanel: MatExpansionPanel;
  constructor() {}
  step = 0;
  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.matExpansionPanel) {
      if (changes.autoCollapseText.currentValue === 'collapseAll') {
        this.matExpansionPanel.close();
      } else if (changes.autoCollapseText.currentValue === 'expandAll') {
        this.matExpansionPanel.open();
      }
    }
  }

  get checkIfString() {
    return typeof this.title === 'string';
  }

  public checkIsBoolean(value): boolean {
    return typeof value === 'boolean';
  }

  onChangeCheckbox(value) {
    this.changeValue.emit(value);
  }

  expandPanel(matExpansionPanel: MatExpansionPanel, event: any) {
    if (!this.expandOnIcon) return;
    event.stopPropagation();
    if (!this._isExpansionIndicator(event.target)) {
      setTimeout(() => {
        matExpansionPanel.close();
      });
    } else {
      this.opened.emit(true);
    }
  }

  onEdit() {
    this.edit.emit(true);
  }

  setOpen(open: boolean) {
    this.panelOpenState = open;
  }

  private _isExpansionIndicator(target: EventTarget | any): boolean {
    const expansionIndicatorClass = 'mat-expansion-indicator';
    return (
      target.classList && target.classList.contains(expansionIndicatorClass)
    );
  }
}
