<div class="c4p-content-menu">

  <button mat-icon-button [matMenuTriggerFor]="menu" class="tuula-top-bar-avatar-button tuula-mobile">
    <ngx-avatars
      [name]="filterName(userInfo?.fullName)"
      [src]="userInfo?.avatar"
      class="topbar-avatar"
      [ngClass]="{'custom-avatar':customStyleForAvatar}"
    ></ngx-avatars>
  </button>

  <button mat-icon-button [matMenuTriggerFor]="menu"
  class="btn-avatar">
    <div class="tuula-user-info">
      <div style="cursor: pointer">
        <ngx-avatars
          [name]="filterName(userInfo?.fullName)"
          [src]="userInfo?.avatar"
          class="topbar-avatar"
          [ngClass]="{'custom-avatar':customStyleForAvatar}"
        ></ngx-avatars>
      </div>
      <div>
        <div class="user-info">
          <span [innerHTML]="userInfo?.firstName | titlecase"></span>
          <span *ngIf="!userInfoService.isClientPortal" class="user-role"
                [innerHTML]="truncateText((currentRole$ | async), 19)"
          >
          </span>
        </div>
      </div>
    </div>
  </button>
</div>


<mat-menu #menu="matMenu" class="c4p-menu tuula-top-bar-menu">
  <div
    *ngFor="
      let action of userInfoService.isClientPortal
        ? clientPortalMenuActions
        : menuActions
    "
  >
    <div
      mat-menu-item
      class="c4p-menu-item"
      (click)="action.method(action.tooltip)"
      [disabled]="$any(action).hasPermission != null && !$any(action).hasPermission"
      *ngIf="action.visible"
    >
      <mat-icon class="c4p-action-menu-icon">{{ action.icon }}</mat-icon>
      <span class="c4p-menu-title">{{ action.tooltip | translate }}</span>
    </div>
  </div>
</mat-menu>
