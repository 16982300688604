<div class="c4p-table-view-wrap">
  <div class="c4p-table-title-bar">
    <span class="c4p-align-left">
      <span class="c4p-horizontal-divider"></span>
      <span class="c4p-ellipsis" translate>{{ options.name }}</span>
      <span class="c4p-horizontal-divider"></span>
      <div *ngIf="options.showSearch && showSearch" class="c4p-search">
        <mat-icon matPrefix class="c4p-search-icon">search</mat-icon>
        <input matInput [formControl]="searchControl" />
      </div>
    </span>
    <span class="c4p-align-right">
      <div
        *ngIf="options.showLoadExisting"
        class="c4p-button"
        (click)="onLoadExisting()"
        translate
      >
        general.labels.LoadExisting
      </div>
      <div
        *ngIf="options.showAdd"
        class="c4p-button"
        (click)="onAdd()"
        translate
      >
        general.labels.Add
      </div>
      <span class="c4p-horizontal-divider"></span>
    </span>
  </div>

  <div class="c4p-grid">
    <div *ngFor="let card of data">
      <div class="c4p-grid-item" *ngIf="widgetName !== 'noteWidget'">
        <app-card-view-2
          *ngIf="view == 'grid'"
          [options]="options"
          [tableMenu]="tableMenu"
          [data]="card"
        ></app-card-view-2>
      </div>
      <div class="c4p-grid-note" *ngIf="widgetName === 'noteWidget'">
        <app-card-view-note
          *ngIf="view == 'grid'"
          [options]="options"
          [data]="card"
        ></app-card-view-note>
      </div>
    </div>
  </div>
  <mat-paginator
    *ngIf="options.showPagination"
    [length]="totalRows"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="pageSizeOptions"
    (page)="handlePage($event)"
  >
  </mat-paginator>
</div>
