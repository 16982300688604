<div [formGroup]="mismatchAnswersForm">
  <div formArrayName="mismatchedQuestionSetAnswers">
    <div
      *ngFor="
        let mismatchedQuestionSetAnswer of mismatchedQuestionSetAnswersFormArray.controls;
        let i = index
      "
    >
      <app-panel type="popup" [formGroup]="$any(mismatchedQuestionSetAnswer)">
        <app-panel-title
          title="{{ 'mismatch-answers.Title' | translate }}"
        ></app-panel-title>
        <ng-container formArrayName="mismatchedQuestions">
          <mismatch-answers-hint
            hint="{{ 'mismatch-answers.Description' | translate }}"
          ></mismatch-answers-hint>
          <div
            class="c4p-mismatch-question-set"
            *ngFor="
              let mismatchedQuestion of $any(mismatchedQuestionSetAnswersFormArray)
                .controls[i].controls.mismatchedQuestions.controls
            "
          >
            <ng-container [formGroup]="mismatchedQuestion">
              <div class="c4p-mismatch-divider-container">
                <mat-divider></mat-divider>
              </div>
              <mismatch-answers-title
                title="{{
                  mismatchedQuestionSetAnswer.get('questionSetAnswerTitle')
                    .value
                }}"
              ></mismatch-answers-title>
              <app-panel-form-row rowClass="c4p-mismatch-answer-row">
                <app-form-input-checkbox
                  width="small-nowidth"
                  formControlName="inheritAnswer"
                ></app-form-input-checkbox>
                <app-question-render-form
                  [inputFormGroup]="
                    mismatchedQuestion.get('entityQuestionAnswer')
                  "
                ></app-question-render-form>
                <app-question-render-form
                  [inputFormGroup]="
                    mismatchedQuestion.get('inheritingQuestionAnswer')
                  "
                ></app-question-render-form>
              </app-panel-form-row>
            </ng-container>
          </div>

          <div style="display: flex">
            <span class="c4p-button-ok" (click)="onConfirm()">
              <span>OK</span>
            </span>
          </div>
        </ng-container>
      </app-panel>
    </div>
  </div>
</div>
