
<mat-form-field
class="custom-date-range-input-form"
[attr.disabled]="disabled() || null"
>
    <mat-label class="custom-date-range-label">{{label() | translate}}</mat-label>

    <div class="custom-date-range-input" [attr.disabled]="disabled() ? '' : null">
      <mat-date-range-input
      [formGroup]="$any(controlContainer.control)"
      [rangePicker]="picker"
      [disabled]="disabled()"
      [required]="required()"
      [min]="minDate()"
      [max]="maxDate()"
      [attr.testId]="testId()"
    >
      <input
        matStartDate
        placeholder="{{ startDatePlaceholder() | translate }}"
        (keydown)="checkDateRegex($event)"
        (blur)="onStartDateChange($event)"
        maxlength="10"
        formControlName="startDate"
        (input)="fixRequiredError($event)"
      />
      <input
        matEndDate
        placeholder="{{ endDatePlaceholder() | translate }}"
        (keydown)="checkDateRegex($event)"
        (blur)="onEndDateChange($event)"
        maxlength="10"
        formControlName="endDate"
        (input)="fixRequiredError($event)"
      />
    </mat-date-range-input>

    <mat-datepicker-toggle
    class="custom-date-range-toggle-suffix"
    [for]="picker"
  ></mat-datepicker-toggle>

</div>
    

    <mat-hint class="custom-date-range-input-hint">{{ hint() | translate }}</mat-hint>
    @if(controlContainer.control?.get('startDate')?.errors || controlContainer.control?.get('endDate')?.errors){
      <mat-error class="custom-date-range-input-error">
        <app-validation-message [errors]="controlContainer.control?.get('startDate')?.errors || controlContainer.control?.get('endDate')?.errors"></app-validation-message>
      </mat-error>
    }
  </mat-form-field>


  <mat-date-range-picker
  #picker
  (closed)="onDateRangeChange()"
  panelClass="custom-range-datepicker-panel-class"
></mat-date-range-picker>