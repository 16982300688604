import { inject } from '@angular/core';
import {
  ActionType,
  SIDE_SHEET_CONFIG,
  SIDE_SHEET_CONTENT,
  SideSheetButtonEvent,
  SideSheetButtonListener,
  SideSheetConfig,
  SideSheetContent,
  SideSheetTooltipButtonEvent,
  SideSheetTooltipButtonListener,
} from '../../../models';
import { SideSheetService } from '../../../services';

export abstract class AbstractSideSheetComponent
  implements SideSheetButtonListener, SideSheetTooltipButtonListener
{

  protected sideSheetService=inject(SideSheetService);
  protected sideSheetContent=inject(SIDE_SHEET_CONTENT,{optional:true});
  protected sideSheetConfig=inject(SIDE_SHEET_CONFIG,{optional:true});
  protected constructor(
  ) {}

  protected triggerCloseSideSheet(event: SideSheetButtonEvent): void {
    if (event?.button?.actionType === ActionType.SIDE_SHEET_CLOSE) {
      const action = this.sideSheetService.createConfirmAction(
        event?.button,
        'general.labels.Confirm',
        'general.messages.LeavePageTitle',
      );
      this.sideSheetService.openTooltip(action);
    }
  }

  protected closeSideSheet(event: SideSheetTooltipButtonEvent): void {
    if (
      event?.button?.actionType === ActionType.SIDE_SHEET_CLOSE &&
      event?.confirmed
    ) {
      this.sideSheetService.closeSideSheet();
    }
  }
  protected closeSideSheetImmediately(event: SideSheetButtonEvent) {
    if (event?.button?.actionType === ActionType.SIDE_SHEET_CLOSE) {
      this.sideSheetService.closeSideSheet();
    }
  }

  protected triggerGoBackPreviousComponent(event: SideSheetButtonEvent): void {
    if (event?.button?.actionType === ActionType.BACK) {
      const action = this.sideSheetService.createConfirmAction(
        event?.button,
        'general.labels.Confirm',
        'general.messages.LeavePageTitle',
      );
      this.sideSheetService.openTooltip(action);
    }
  }

  protected goBackPreviousComponent(event: SideSheetTooltipButtonEvent): void {
    if (event?.button?.actionType === ActionType.BACK && event?.confirmed) {
      this.sideSheetService.closeComponent();
    }
  }
  protected goBackPreviousComponentImmediately(event: SideSheetButtonEvent) {
    if (event?.button?.actionType === ActionType.BACK) {
      this.sideSheetService.closeComponent();
    }
  }

  onClickButton(event: SideSheetButtonEvent): void {
    if (!this.sideSheetConfig.closeWithTooltip) {
      this.closeSideSheetImmediately(event);
      this.goBackPreviousComponentImmediately(event);
    } else {
      this.triggerGoBackPreviousComponent(event);
      this.triggerCloseSideSheet(event);
    }
  }

  onClickTooltipButton(event: SideSheetTooltipButtonEvent): void {
    this.closeSideSheet(event);
    this.goBackPreviousComponent(event);
  }
}
