import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT } from '../../../../form-controls/form-controls.const';
import { UserInfoService } from '../../../../services';
import { DateUtils } from '../../../../utilities';
import { ModelUtils } from '../../../../utilities/model.utils';
import { netherlandsBsnValidator } from '../../../../validators/netherlands-bsn.validator';
import { EmployeeOverviewService } from '../../services/employee-overview.service';

@Component({
  selector: 'app-care-program-overview-filter-form',
  templateUrl: './care-program-overview-filter-form.component.html',
  styleUrls: ['./care-program-overview-filter-form.component.scss'],
})
export class CareProgramOverviewFilterFormComponent
  implements OnInit, OnDestroy
{
  @Input() carefileView: boolean = true;
  @Input() careprogramsLength: number;
  @Output() submit = new EventEmitter();
  private readonly destroyed$ = new Subject<boolean>();
  private isClientSearch = false;
  mainTherapistFilterObject = { status: 'active' };
  constructor(
    private formBuilder: FormBuilder,
    public employeeService: EmployeeOverviewService,
    private userInfoService: UserInfoService,
  ) {}

  filtersForm: FormGroup = null!;

  ngOnInit(): void {
    this.filtersForm = this.buildFiltersForm();
    this.isClientSearch = !this.carefileView && this.careprogramsLength === 0;
    if (this.isClientSearch) {
      this.filtersForm.controls['therapist.id'].disable({ emitEvent: false });
      this.filtersForm.controls['registrationDate'].disable({
        emitEvent: false,
      });
    }
    this.initFilterValues();
    this.filtersForm.valueChanges
      .pipe(
        debounceTime(DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT),
        map((values) => (ModelUtils.isObjectEmpty(values) ? null : values)),
        takeUntil(this.destroyed$),
      )
      .subscribe((values) => {
        this.submit.emit(values);
        this.setFilterValues();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  // For client date of birth
  getMaxDate() {
    return DateUtils.getCurrentTenantDayDate() as any;
  }

  initFilterValues(): void {
    this.filtersForm.patchValue(
      this.userInfoService.getFiltersForPage('Careprogram'),
    );
  }

  setFilterValues(): void {
    this.userInfoService.setFiltersForPage(
      'Careprogram',
      this.filtersForm.value,
    );
  }
  buildFiltersForm(){
    return this.formBuilder.group({
      'client.clientNumber': [],
      bsn: [null, [netherlandsBsnValidator]],
      'client.fullName': [],
      'client.dateOfBirth': [],
      'therapist.id': [],
      registrationDate: [],
    });
  }
}
