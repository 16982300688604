import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewEncapsulation } from '@angular/core';
import { ActionType, Button, SideSheetConfig, SideSheetContent} from '../../../models/side-sheet';
import { SideSheetService } from '../../../services';
import { Subscription } from 'rxjs';
import { createButton } from '../../../services/side-sheet/initial-side-sheet-config';
import { NgIf, NgStyle } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-sidesheet-title-bar',
  templateUrl: './sidesheet-title-bar.component.html',
  styleUrls: ['./sidesheet-title-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone:true,
  imports: [NgIf,MatIconModule,TranslateModule,NgStyle]
})
export class SidesheetTitleBarComponent implements OnInit, OnDestroy {
  @Input() sideSheetConfig: SideSheetConfig;
  @Input() sideSheetContent: SideSheetContent;
  @Input() titleBarActionsTemplate: TemplateRef<any>;

  @Output() buttonClicked = new EventEmitter<Button>();
  // @Output() closeSidesheet = new EventEmitter<Button>();

  closeButton: Button;
  backButton: Button;
  private subscription: Subscription = new Subscription();

  constructor(private sideSheetService: SideSheetService) {
    this.closeButton = createButton('', ActionType.SIDE_SHEET_CLOSE);
    this.backButton = createButton('', ActionType.SIDE_SHEET_BACK);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  toggleFullScreen(): void {
    this.sideSheetConfig.fullscreen = !this.sideSheetConfig.fullscreen;
  }

  onCloseButton(){
    this.buttonClicked.emit(this.closeButton);
  }
  onBackButton(){
    this.buttonClicked.emit(this.backButton);
  }
}
