import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[sideSheetHost]',
  standalone:true
})
export class SideSheetHostDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
