import { IAdditionalMenu } from "./IAdditionalMenu";

export interface IMenuItems {
  icon: string;
  tooltip: string;
  method: any;
  disabled?: boolean;
  id?: string;
  additionalMenus?: IAdditionalMenu[];
}
