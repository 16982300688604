<div class="c4p-form-input">
  <mat-form-field>
    <mat-label>{{placeholder | translate}}</mat-label>

    <input
      type="text"
      matInput
      [formControl]="autoCompleteControl"
      [errorStateMatcher]="errorStateMatcher"
      [required]="required"
      (blur)="onTouchedCallback()"
      [matAutocomplete]="auto"
    />
    <mat-error *ngFor="let error of ngControl.errors | keyvalue">
      @if(errorLabels && errorLabels[error.key]){
        <span>
          {{ errorLabels[error.key] | translate }}
        </span>
      }@else {
        <ng-container #defaultError *ngIf="DEFAULT_ERRORS.has(error.key)">
          <span>{{ DEFAULT_ERRORS.get(error.key) | translate }}&nbsp;</span>
        </ng-container>
      }
      
    </mat-error>
    <span *ngIf="suffix" matSuffix>{{ suffix }}</span>
  </mat-form-field>
</div>

<mat-autocomplete
  #auto="matAutocomplete"
  [displayWith]="getDisplayValue.bind(this)"
  (optionSelected)="onSelectionChange($event)"
>
  <mat-option
    *ngFor="
      let option of options.length > 0 ? options : (filteredOptions$ | async)
    "
    [value]="option.value"
    >{{ option.label | translate }}</mat-option
  >
</mat-autocomplete>
