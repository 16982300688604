import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FILE_TYPES } from '../../../const/file-types.const';
import { DocumentModel, DocumentService, HttpUtils } from 'c4p-portal-util';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-document-upload-detail',
  templateUrl: './document-upload-detail.component.html',
  styleUrl: './document-upload-detail.component.css'
})
export class DocumentUploadDetailComponent implements OnInit, OnDestroy {

  @Input() document: File;
  @Input() categories: any[] = [];
  @Input() services: any[] = [];
  @Input() carefileId: string;
  @Input() applicationId: string;
  @Input() careprogramId: string;
  @Input() clientId: string;
  @Output() delete = new EventEmitter<void>();
  protected readonly HttpUtils = HttpUtils;
  uploadProgress = 0;
  uploadStatus = '';
  iconPath: string = '/assets/svg/default-file.svg';
  panelExpanded = true;
  uploadFailed = false;
  isUploading = false;
  private readonly destroyed$ = new Subject<boolean>();

  public documentForm: FormGroup = null!;

  constructor(public documentService: DocumentService,
              private fb: FormBuilder) { }

  ngOnInit(): void {
    this.documentForm = this.documentBuildForm();
    this.getFileIcon();
    this.documentForm.valueChanges.subscribe(() => {
      const allTouched = Object.values(this.documentForm.controls).every(control => control.touched);

      if (allTouched && this.documentForm?.invalid) {
        this.uploadFailed = true;
        this.uploadStatus = 'document.messages.fillInRequiredFields';
      }
    });
  }

  getFileIcon() {
    const fileType = this.document.name.substring(
      this.document.name.lastIndexOf('.') + 1,
    );
    if (FILE_TYPES[fileType]) {
      this.iconPath = `/assets/svg/${FILE_TYPES[fileType]}`;
    }
  }

  ngOnDestroy(): void {
    this.destroyed$?.next(true);
    this.destroyed$?.complete();
  }

  deleteDocument() {
    if (this.uploadProgress === 0) {
      this.delete.emit();
    }
  }

  togglePanel() {
    this.panelExpanded = !this.panelExpanded;
  }

  uploadDocument(): void {
    const documentDTO = new DocumentModel();

    if (this.documentForm?.invalid) {
      this.documentForm.markAllAsTouched();
      this.uploadFailed = true;
      this.uploadStatus = 'document.messages.fillInRequiredFields';
      return;
    } else if (this.uploadProgress > 0 || this.isUploading) {
      return;
    } else {
      documentDTO.description = this.documentForm?.value?.description;
      documentDTO.category = this.documentForm?.value?.category;
      documentDTO.services = this.documentForm?.value?.services;
      documentDTO.carefileId = this.carefileId;
      documentDTO.applicationId = this.applicationId;
      documentDTO.careprogramId = this.careprogramId;
      documentDTO.client = <any>{ id: this.clientId};
      documentDTO.fileData = {
        fileName: this.document?.name,
        extension: this.document?.name?.substring(
          this.document?.name?.lastIndexOf('.') + 1,
        ),
        fileSize: HttpUtils.calculateFileSize(this.document?.size ?? 0),
      };
      this.uploadFailed = false;
      this.uploadStatus = '';
    }

    this.documentService
      ?.createMultipartDocument(documentDTO, this.document)
      ?.pipe(takeUntil(this.destroyed$))
      .subscribe(
        (response) => {
          this.uploadProgress = response?.uploadProgress;
          this.uploadStatus = response?.status;
          this.isUploading = response?.uploadProgress < 100;
        },
        (error) => {
          this.uploadFailed = true;
          this.isUploading = false;
          this.uploadProgress = 0;
          this.uploadStatus = 'document.messages.uploadFailed';
        },
      );
  }

  documentBuildForm() {
    return this.fb.group({
      description: [null, Validators.required],
      category: [null, Validators.required],
      services: [null, Validators.required],
    });
  }
}
