import { Component, OnDestroy, OnInit, input } from '@angular/core';
import { SideSheetConfig, SideSheetContent } from '../../../models';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


@Component({
  selector: 'app-sidesheet-loading',
  templateUrl: './sidesheet-loading.component.html',
  styleUrl: './sidesheet-loading.component.scss',
  standalone:true,
  imports:[MatProgressSpinnerModule]
})
export class SidesheetLoadingComponent implements OnInit, OnDestroy {
  readonly sideSheetConfig = input<SideSheetConfig>(undefined);
  readonly sideSheetContent = input<SideSheetContent>(undefined);

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }

}
