import { Component, EventEmitter, Inject, input, Input, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { HOUR_REGEX, MINUTE_REGEX } from '../../constants/form-controls.const';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskDirective } from 'ngx-mask';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-nd-form-input-time-picker',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatInputModule,
    NgxMaskDirective,
    TranslateModule,
  ],
  templateUrl: './nd-form-input-time-picker.component.html',
  styleUrl: './nd-form-input-time-picker.component.scss'
})
export class FormInputTimePickerNdComponent implements OnInit {
  readonly selectedDate = input<Date | null>(null);
  @Input() timeForm: FormGroup;
  @Output() closeTimePicker = new EventEmitter<void>();
  
  hourRegex = HOUR_REGEX;
  minuteRegex = MINUTE_REGEX;
  selectedTime = { hours: 0, minutes: 0 }; // Initial time
  hour:any;
  minute:any;
  constructor(public fb: FormBuilder,@Optional() @Inject('TIME_FORM') form:FormGroup) {
    if(form) this.timeForm = form;
  }
  onHourChange(plus: boolean): void {
    if (!this.minute) {
      this.formattedTime();
    }
    if (plus) {
      this.selectedTime.hours = (this.selectedTime.hours + 1) % 24;
      this.hour = this.selectedTime.hours;
    } else {
      this.selectedTime.hours = (this.selectedTime.hours - 1 + 24) % 24;
      this.hour = this.selectedTime.hours;
    }
    this.formattedTime();
  }
  onMinuteChange(plus: boolean): void {
    if (!this.minute) {
      this.formattedTime();
    }
    if (this.selectedTime.minutes == 0) {
      this.formattedTime();
    }
    if (plus) {
      this.selectedTime.minutes += 1;
      if (this.selectedTime.minutes === 60) {
        this.selectedTime.minutes = 0;
        this.onHourChange(true);
        this.minute = this.selectedTime.minutes;
      }
    } else {
      this.selectedTime.minutes -= 1;
      if (this.selectedTime.minutes < 0) {
        this.selectedTime.minutes = 59;
        this.onHourChange(false);
        this.minute = this.selectedTime.minutes;
      }
    }
    this.formattedTime();
  }

  formattedTime(): string {
    this.hour = this.selectedTime.hours.toString().padStart(2, '0');
    this.minute = this.selectedTime.minutes.toString().padStart(2, '0');
    this.timeForm.get('hour').setValue(this.hour);
    this.timeForm.get('minute').setValue(this.minute);
    return `${this.hour}:${this.minute}`;
  }

  ngOnInit(): void {
    if (!this.timeForm)
      this.timeForm = this.fb.group({
        hour: [null, []],
        minute: [null, []],
      });
    const rawValue = this.timeForm.getRawValue();
    if (rawValue) {
      this.selectedTime.hours = rawValue.hour
        ? Number.parseInt(rawValue.hour)
        : 0;
      this.selectedTime.minutes = rawValue.minute
        ? Number.parseInt(rawValue.minute)
        : 0;
      this.formattedTime();
    }
  }

  minutesChange(event: Event): void {
    const value = (event.target as HTMLInputElement).value;

    if (!value) {
      this.selectedTime.minutes = 0;
      this.minute = this.selectedTime.minutes;
      this.formattedTime();
      this.timeForm.get('minute').setValue(this.minute);
    } else {
      let minute = Number.parseInt(value);
      if (minute > 59) {
        minute = 59;
      }
      this.selectedTime.minutes = minute;
      this.minute = this.selectedTime.minutes;
      this.formattedTime();
    }
  }
  hoursChange(event: Event): void {
    const value = (event.target as HTMLInputElement).value;

    if (!value) {
      this.selectedTime.hours = 0;
      this.hour = this.selectedTime.hours;
      this.formattedTime();
      this.timeForm.get('hour').setValue(this.hour);
    } else {
      let hour = Number.parseInt(value);
      if (hour > 23) {
        hour = 23;
      }
      this.selectedTime.hours = hour;
      this.hour = this.selectedTime.hours;
      this.formattedTime();
    }
  }
  closeInput() {
    this.closeTimePicker.emit();
  }
}
