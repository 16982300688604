import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideSheetHostDirective } from './side-sheet-host.directive';
import { ClickableDirective } from './clickable.directive';

const moduleComponents = [
  SideSheetHostDirective,
  ClickableDirective
];
@NgModule({
  imports: [CommonModule,...moduleComponents],
  declarations: [],
  exports: [...moduleComponents],
  providers: [],
})
export class DirectivesModule {}
