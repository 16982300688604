import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { SocialAreaHelper } from '../../social-area.helper';
import { SocialAreaModel } from '../../../../models/social-area/social-area.model';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { GoalsConfigFormComponent } from '../../goal-config-form/goal-config-form.component';
import { ObjectiveOverviewService } from '../../objective-overview.service';
import { PermissionService } from '../../../../services/permissions/permission.service';
import { DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT } from '../../../../form-controls/form-controls.const';
import { GoalHistoryComponent } from '../goal-history/goal-history.component';

@Component({
  selector: 'app-goal',
  templateUrl: './goal-form.component.html',
  styleUrls: ['./goal-form.component.scss'],
  providers: [ObjectiveOverviewService],
})
export class GoalFormComponent implements OnInit, OnDestroy, OnChanges {

  @Input() data: any = {};
  @Input() entityId: string;
  @Input() entity: string;
  @Input() entityVersion: number;
  @Input() viewMode = false;
  @Input() scoringEnabled = false;
  @Input() scoringUnanswered$ = new Subject<void>();

  @Input() carefileId?: string = null;
  @Input() careplanId?: string = null;
  @Input() careplanVersion?: number = null;
  @Input() previewMode?: boolean = false;
  @Input() careplan = null;

  @Output() updateRequest = new EventEmitter<SocialAreaModel>();

  socialAreaHelper: SocialAreaHelper;

  socialAreas: SocialAreaModel[];
  selectedSocialArea: SocialAreaModel;
  searchControl: FormControl = new FormControl();
  socialAreas$ = new BehaviorSubject<any>(null);

  selectedSocialAreaForm: FormGroup = null!;
  public get mainGoalsFormArray(): FormArray {
    return this.selectedSocialAreaForm.get('mainGoals') as FormArray;
  }
  private readonly destroyed$ = new Subject<boolean>();

  constructor(
    public formBuilder: FormBuilder,
    public dialog: MatDialog,
    public permissionService: PermissionService,
    public objectiveOverviewService: ObjectiveOverviewService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.data &&
      changes.data.currentValue !== changes.data.previousValue
    ) {
      this.selectedSocialAreaForm.patchValue(changes.data, {
        emitEvent: false,
      });
      if (
        this.entity === 'evaluation' &&
        changes.data.currentValue.mainGoals !== undefined &&
        this.mainGoalsFormArray
      ) {
        this.updateStateAchievedCheckboxes(changes.data.currentValue.mainGoals);
      }
    }
  }

  ngOnInit(): void {
    this.selectedSocialAreaForm = this.selectedSocialAreaBuildForm();
    this.socialAreaHelper = new SocialAreaHelper(
      this.formBuilder,
      this.destroyed$,
      this.scoringEnabled,
      this.viewMode,
    );
    this.selectedSocialArea = this.data;
    this.buildForm();
    if (this.viewMode) {
      this.selectedSocialAreaForm.disable();
    }
    this.selectedSocialAreaForm.get('additionalInfo').disable();
    this.initAutosaveSubscription();
    this.scoringUnanswered$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.selectedSocialAreaForm.markAllAsTouched());
  }

  ngOnDestroy(): void {
    this.selectedSocialAreaForm.reset({ emitEvent: false });
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  buildForm(): void {
    this.selectedSocialAreaForm =
      this.socialAreaHelper.buildSocialAreaFormGroup(
        this.selectedSocialArea,
        this.entity === 'evaluation',
      );
  }

  private initAutosaveSubscription() {
    this.selectedSocialAreaForm.valueChanges
      .pipe(
        debounceTime(DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        if (!this.previewMode) {
          const updateSocialArea: SocialAreaModel =
            this.selectedSocialAreaForm.getRawValue();
          if (this.selectedSocialAreaForm.dirty) {
            updateSocialArea.scoringAnswered =
              this.selectedSocialAreaForm.valid;
            this.updateRequest.emit(updateSocialArea);
          }
        }
      });
  }

  public onConfigureGoals(): void {
    if (!this.viewMode) {
      const dialogRef = this.dialog.open(GoalsConfigFormComponent, {
        data: {
          refSocialAreaId: this.selectedSocialArea.refId,
          mainGoals: this.mainGoalsFormArray.value,
          additionalInfo: this.selectedSocialArea.additionalInfo,
        },
      });
      dialogRef.afterClosed().subscribe((dialogData) => {
        if (dialogData) {
          this.selectedSocialArea.mainGoals = dialogData.mainGoals;
          this.selectedSocialArea.additionalInfo = dialogData.additionalInfo;
          this.selectedSocialAreaForm =
            this.socialAreaHelper.buildSocialAreaFormGroup(
              this.selectedSocialArea,
            );
          this.updateRequest.emit(this.selectedSocialAreaForm.getRawValue());
        }
      });
    }
  }

  openGoalHistory(control: any, type: string) {
    const dialogRef = this.dialog.open(GoalHistoryComponent, {
      data: {
        carefileId: this.carefileId,
        careplanId: this.careplanId,
        goalType: type,
        entityId: this.entityId,
        entity: this.entity,
        entityVersion: this.entityVersion,
        goalName: control.value.name,
        goalRefId: control.value.refId,
        socialAreaId: this.selectedSocialArea.id,
        careplan: this.careplan,
      },
    });
    dialogRef.afterClosed().subscribe((dialogData) => {});
  }

  private updateStateAchievedCheckboxes(mainGoalsChanges: any) {
    for (let mainGoalFormGroup of this.mainGoalsFormArray.controls) {
      const mainGoalData = mainGoalsChanges.find(
        (mainGoal) =>
          mainGoal.name === mainGoalFormGroup.get('name').value &&
          mainGoal.refId === mainGoalFormGroup.get('refId').value,
      );
      if (mainGoalData) {
        mainGoalFormGroup
          .get('achieved')
          .setValue(mainGoalData.achieved, { emitEvent: false });
        mainGoalData.manualMarkedEnabled
          ? mainGoalFormGroup.get('achieved').enable({ emitEvent: false })
          : mainGoalFormGroup.get('achieved').disable({ emitEvent: false });
      }
    }
  }
  selectedSocialAreaBuildForm(){
    return  this.formBuilder.group({});
  }

  subGoalAchievedManuallyMarked($event: Event,_t87: any) {
    throw new Error('Method not implemented.');
    }
}
