import { NgModule } from '@angular/core';
import { BreadcrumbStComponent } from './breadcrumb-standalone/breadcrumb-st.component';

const moduleComponents = [
 BreadcrumbStComponent
];

@NgModule({
  imports: [
    ...moduleComponents
  ],
  declarations: [],
  exports: [...moduleComponents],
  providers: [
  ],
})
export class StandaloneComponents {}
