<app-page-content [formGroup]="careprogramForm">
  <app-panel>
    <app-panel-title
      title="careprogram.titles.Careprogram"
      titleIcon="content_paste"
    >
    </app-panel-title>
    <app-panel-form-row>
      <app-form-input-text-area
        formControlName="careProgramId"
        [readonly]="true"
        placeholder="careprogram.labels.CareProgramNumber"
        style="width: 350px; margin: 0px 0px 0px 10px"
        width="-nowidth"
      ></app-form-input-text-area>

      <app-form-input-date
        formControlName="registrationDate"
        placeholder="careprogram.labels.RegistrationDate"
        [maxDate]="getMaxDate(careprogramForm.get('endDate').value)"
      ></app-form-input-date>
      <app-form-input-date
        formControlName="endDate"
        placeholder="general.labels.EndDate"
        [maxDate]="endDateMaxDate"
        [minDate]="getMinDate(careprogramForm.get('registrationDate').value)"
      ></app-form-input-date>
      <app-form-input-dropdown
        formControlName="defaultSetting"
        [required]="true"
        placeholder="careprogram.labels.DefaultSetting"
        [options]="settingTypeService.values"
        [maxContentSelectionPanel]="true"
      ></app-form-input-dropdown>
      <ng-container>
        <app-form-input-dropdown
          placeholder="general.menu.Client"
          formControlName="client"
          [dropdownService]="clientService"
          [options]="$any(clientService.values)"
          [search]="true"
          [objectAsValue]="true"
          labelProperty="fullName"
          [selectSingle]="true"
          [readonly]="isCarefileCareProgram"
          [maxContentSelectionPanel]="true"
        ></app-form-input-dropdown>
        <app-label
          [labelValue]="{
            label: 'careprogram.labels.ClientDateOfBirth',
            value:
              this.careprogramForm.get('client').value?.dateOfBirth | appDate
          }"
        ></app-label>
        <app-label
          [labelValue]="{
            label: 'careprogram.labels.ClientId',
            value: this.careprogramForm.get('client').value?.clientNumber
          }"
        ></app-label>
        <app-label
          [labelValue]="{
            label: 'careprogram.labels.Carefile',
            value: this.carefile | carefileDisplay
          }"
        ></app-label>
        <app-form-input-dropdown
          formControlName="services"
          placeholder="productCatalog.labels.Service"
          [options]="$any(carefileServiceService.values)"
          [dropdownService]="carefileServiceService"
          [filterObject]="filterObjectService"
          [selectSingle]="true"
          [multiple]="true"
          labelProperty="name"
          [objectAsValue]="true"
          [maxContentSelectionPanel]="true"
        ></app-form-input-dropdown>
      </ng-container>
      <app-form-input-dropdown
        formControlName="fundingStream"
        placeholder="careprogram.labels.FundingStream"
        [options]="fundingStream.values"
        [readonly]="true"
      ></app-form-input-dropdown>
      <app-form-input-checkbox
        formControlName="privacy"
        placeholder="general.labels.Privacy"
      ></app-form-input-checkbox>
    </app-panel-form-row>
  </app-panel>
  <app-panel>
    <app-panel-title
      title="careprogram.titles.RestitutionPeriod"
      titleIcon="date_range"
    >
    </app-panel-title>
    <app-panel-form-row>
      <app-form-input-date
        formControlName="restitutionStartDate"
        placeholder="careprogram.labels.RestitutionStartDate"
        [maxDate]="restitutionMaxStartDate"
      ></app-form-input-date>
      <app-form-input-date
        formControlName="restitutionEndDate"
        placeholder="careprogram.labels.RestitutionEndDate"
        [minDate]="restutionMinEndDate"
      ></app-form-input-date>
    </app-panel-form-row>
  </app-panel>
  <lib-main-therapist-section
    class="c4p-flex-column phone-column"
  ></lib-main-therapist-section>
</app-page-content>
