<ng-template #actionTemplate>

  @if (!overflow) {
  <div matTooltip="{{name() | translate}}" [class.disabled]="disabled()" (click)="handleClick()">
    <mat-icon class="tuula-action-icons-table"> {{ icon() }} </mat-icon>
  </div>
  } @else {
  <div mat-menu-item class="c4p-menu-item tuula-action-btn-wrapper" [class.disabled]="disabled()" (click)="handleClick()">
    <mat-icon class="c4p-action-menu-icon tuula-action-icon"> {{ icon() }} </mat-icon>
    <span class="tuula-action-text">{{name() | translate}}</span>
  </div>
  }
</ng-template>
